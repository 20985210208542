@charset "utf-8";
//*****************下層*****************//
.ic{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 0;
    margin: auto;
  }

  &__contact{
    padding-left: 25px;
    &:before{
      width: 18px;
      height: 13px;
      background: url("/wp-content/uploads/ic_mail.png") no-repeat center/contain;
    }
  }

  &__contact2{
    padding-left: 30px;
    &:before{
      width: 24px;
      height: 17px;
      background: url("/wp-content/uploads/ic_mail2.png") no-repeat center/contain;
    }
  }
}

.tel_num{
  .js-tel{
    @include fontsize(30);
    position: relative;
    padding-left: 25px;
    font-weight: 500;
    color: $color_primary;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      bottom: 0;
      margin: auto;
      width: 18px;
      height: 22px;
      background: url("/wp-content/uploads/ic_tel.png") no-repeat center center/cover;
    }
    a{
      color: $color_primary;
    }
  }
}

.anchor_list {
  background: $color_highlight_bg;
  padding: 1em;
  list-style: none;
  border-radius: 10px;
  li {
    margin: .5em .5em;
    a {
      padding-left: 20px;
      position: relative;
      color: $color_text;
      text-decoration: none;
      transition: all .3s;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $color_primary;
        position: absolute;
        top: .5em;
        left: 0;
      }

      &:hover{
        color: $color_primary;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5em 2em;
    gap: 5px 2%;
    &.anchor_center {
      justify-content: center;
    }
    &.col1{
      li{
        width: 100%;
      }
    }

    li{
      width: calc(98% / 2);
      margin: 0;
    }

    &.col3{
      li{
        width: calc(96% / 3);
      }
    }
  }
}

.box_bg{
  background: $color_highlight_bg;
  padding: 4%;
  border-radius: 10px;
  &:not(:last-child){
    margin-bottom: 30px;
  }
  @media screen and (min-width: 768px) {
    &.col{
      margin-bottom: 0;
    }
  }
}

.box_bg2{
  background: rgba(#44b4a5, .1);
  padding: 4%;
  border-radius: 10px;
}

.box_border{
  border: 1px solid $color_primary;
  padding: 4%;
  border-radius: 10px;
  margin-top: 0!important;
}

ul{
  &.box_border{
    list-style: none;
  }
}

section{
  &.highlight{
    &:last-child{
      margin-bottom: 0;
    }

    .box_bg{
      background: white;
    }
  }
}

.list2{
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 2%;
    li{
      width: 49%;
    }
  }
}
.list3{
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 2%;
    li{
      width: calc(96% / 3);
    }
  }
}