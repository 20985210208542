@charset "utf-8";
// ver0.0.1
$base_font_size: 16; //ベースフォントサイズ（単位px）
$sp_base_font_size: 16; //SP用ベースフォントサイズ（単位px）
$header_font_size: 16; //ヘッダフォントサイズ ナビゲーション等の兼ね合いで収まらない場合に調整する（単位px）
$footer_font_size: 16; //フッタフォントサイズ （単位px）
$container: 1000; // container幅（単位px）
$header_break_point: 1000; // ヘッダーのブレイクポイント（ロゴ/Gナビの調整が難しい場合1000px等に設定する）
$fixed_header: false; // 固定ヘッダー true or false（SP時は常にヘッダ固定の仕様のため注意すること）
$header_alpha: false; // ヘッダの高さを無くし透過させるか　true or false（調整中）
$header_search: false; //グローバルメニューの検索ボタン
$grid_column-gap: 0.02; //グリッドの横方向ギャップ幅 %（デフォルトは2% = 0.02 小数で入れること）
$grid_row-gap: 20; //グリッドの縦方向ギャップ幅 px（デフォルトは20）
$page_col: '1col'; //下層ページのカラム数 1col or 2col（投稿ページは2col）
$sp_content_padding: 20; //SP時のコンテンツ左右の余白（px）
$fixed_pagetop＿bottom: true; // ページトップボタンをフッタで固定 true or false

//TODO z-index管理用関数

//カラー設定
@import "foundation/color";

// ======== フォント設定
// 游ゴシック
$font_base_gothic: -apple-system, BlinkMacSystemFont, Arial, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;

// ゴシック Noto Sans
$font_gothic_noto: -apple-system, BlinkMacSystemFont, Arial, "Noto Sans JP", sans-serif;

// メイリオ（Macではデバイスフォントになるので注意すること）
$font_gothic_meiryo: -apple-system, BlinkMacSystemFont, Arial, Meiryo, sans-serif;

// 明朝
$font_base_mincho: "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;

// 明朝 Noto Serif
$font_mincho_noto: "Times New Roman", "Noto Serif JP", serif;

//Zen maru
$zen: "Zen Maru Gothic", system-ui;

//フォント選択（上記5つから選択すること）
$base_font_family: $zen;

//アイコンフォント選択（使いすぎはパフォーマンスに影響するのでどちらかにすること）
$material_font: false;
$font_awesome: true;

// ======== フォントインポート（選択状況に応じてインポートしているので触らないこと）
@if $base_font_family == $font_gothic_noto {
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap'); //Noto Sans
}

@if $base_font_family == $font_mincho_noto {
  @import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap'); //Noto Serif
}

@if $material_font == true {
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); //Material Icon
}

@if $font_awesome == true {
  @import url('https://use.fontawesome.com/releases/v5.6.1/css/all.css'); //FontAwesome5
}

// ======== common
// svg形式のロゴのサイズを正確に入れる（PSDまたはSVGコードを参照すること）
$logo_width: 281; // ロゴの横幅
$logo_height: 50; // ロゴの縦幅
$logo_tab_width: 240; //タブレット時のロゴ横幅（横768～1000）
$logo_sp_width: 200; // スマホ時のロゴ横幅（横320に対して、ハンバーガーメニューを加えても余裕のある値にしておく）

$header_height: 140; // ヘッダーの高さ
$sp_header_height: 60; // SPヘッダーの高さ（ヘッダブレイクポイント以下の場合）

$header_type: A; // ヘッダタイプ　'A'（後日追加予定）
$header_container: false; // ヘッダをコンテナサイズに含めるか　true（コンテナ幅） or false（モニター幅）
$navi_event_type: 'hover'; // グローバルナビ ドロップダウンの動作方法　'click' or 'hover' (clickは孫メニュー非対応)
$hamburger_menu_type: 'B';//A：通常タイプ, B：テキスト付きタイプ


//$header_menu_type: A; // ヘッダドロップダウンメニュータイプ　'A' or 'B' or 'C'（メガメニュー含めて実装予定）

// ======== main_visual
// スライドの種類
// true ・・・ fade
// false ・・・ slide
//各種オプションはmain.jsで編集すること
$main_slide_width: 1920; //スライド画像のwidth
$main_slide_height: 540; //スライド画像のheight
$main_slide_sp_width: 750;
$main_slide_sp_height: 700;
$main_slide_correct: ($main_slide_sp_height / $main_slide_sp_width)*100; //spサイズ時のpadding-topの値(%)
$main_slide_break_point: 768; // PC・SP画像を切替えるブレイクポイント

// スライド画像の見せ方
// 'A'・・・ブレイクポイント～メイン画像の最大サイズまでは高さが最大サイズで固定となるスライド。
// 'B'・・・常に画像全体が見えているスライド。縦横比率を維持しながらリサイズされる。
// 'C'・・・全画面スライド
$main_slide_type: A;

// スライド上のコピー画像
// スライドに応じてFadeするか true or false
// trueの場合、　.main_visual_slide li　と　.main_visual_copy li の数は
// 一致している必要がある
$main_slide_copy_sync: false; // スライドに応じてFadeするか true or false
$main_slide_copy_width: 670; //スライド上のコピー画像のwidth
$main_slide_copy_height: 153; //スライド上のコピー画像のheight

// ======== button
$button_width: 220px; // ボタン幅（max-widthとwidth:100%が指定される）
$style_button_border: $color_primary solid 1px; // ボーダースタイル
$style_button_border_radius: 0px; // 角丸(px)

// 設定ここまで
// ========================================================================================
@import "foundation/_responsive_gs_gap";
//@import "foundation/_responsive_gs";
//_responsive_gsは旧来のflex仕様のもの（IE、印刷対応が必要な場合に使う）

@import "foundation/mixin";

//ベース
@import "foundation/base";

//レイアウト
@import "layout/hdrA";
@import "layout/footer";
@import "layout/main_slide";
@import "layout/headline";

//以下変更しない
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_bread.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_button.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_inquiry.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_loopSlider.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_menu.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_modaal.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_movie.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_news.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_pagetop.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_question_answer.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_search.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_shopinfo.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_sitemap.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_sns.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_sp_image_cols.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_step.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/component/_table.scss";

@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/project/_archive.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/project/_post.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/project/_single.scss";

@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/utility/_ofi.scss";
@import "C:/xampp/htdocs/projects/src/kitanaralaw.com/assets/sass/object/utility/_utility.scss";


@import "common";
@import "topPage";
@import "pages";
