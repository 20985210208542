@charset "utf-8";
.home{
  footer{
    border-top: 0;
  }
}
footer {
  border-top: 2px solid $color_primary;
  position: relative;
  padding: 60px 0;
  font-size: $footer_font_size+px;

  .fNav {
    ul {
      @include listclear();
      li {
        a {
          display: block;
          padding: .3em .3em;
          text-decoration: none;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            left: 0;
            top: .6em;
          }
          &:hover{
            text-decoration: underline;
          }
        }

        &.menu-item-has-children {
          ul {
            display: block !important;
          }
        }
      }
    }

    >ul{
      >li{
        >a{
          padding-left: 15px;
          &:before{
            top: .7em;
            width: 5px;
            height: 5px;
            border: 6px solid transparent;
            border-left: 6px solid $color_primary;
            border-right: 0;
          }
        }
        >.sub-menu{
          >li{
            >a{
              &:before{
                width: 6px;
                height: 2px;
                background: $color_primary;
                top: .9em;
              }
            }
            .sub-menu{
              li{
                a{
                  &:before{
                    top: .8em;
                    width: 4px;
                    height: 4px;
                    border-bottom: 1px solid $color_primary;
                    border-right: 1px solid $color_primary;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .copy_right {
    @include fontsize(10);
  }
}

@media (max-width: 767px) {
  footer {
    padding: 2em 0 30px;
    .col {
      margin-bottom: 0 !important;
    }

    .fNav{
      ul{
        li{
          border-bottom: 1px solid $color_ftnav_border_sp;
          a{
            padding: 1em .3em;
            &:before{
              top: 1.6em!important;
            }
          }
          .sub-menu{
            li{
              &:first-child{
                border-top: 1px solid $color_ftnav_border_sp;
              }
              &:last-child{
                border: 0;
              }
            }
          }
        }
      }

      >ul{
        >li{
          >a{
            padding-left: 15px;
          }

          >.sub-menu{
           >li{
            >a{
              padding-left: 30px;
              &:before{
                left: 15px;
              }
            }
            >.sub-menu{
              >li{
               >a{
                 padding-left: 45px;
                 &:before{
                   left: 30px;
                 }
               }
              } 
             }
           } 
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  footer {
    .fNav {
      margin-bottom: 5%;
      >ul{
        >li{
          .sub-menu{
            padding-left: 1em;
            >li{
              >a{
                padding-left: 15px;
              }
              .sub-menu{
                li{
                  a{
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.copy_right {
  background: $color_footer_bg;
  color: $color_footer_text;
  @include fontsize(16);
  margin: 0;
  padding: 2em 0;
  border-top: 1px solid white;
  @media (max-width: 767px) {
    padding: 1em 0;
  }
}

// スマホナビゲーション折り返し設定（タブレット）
@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li {
    &.tablet-break:after {
      content: "\A";
      white-space: pre;
    }

    &.tablet-break + li:before {
      display: none;
    }
  }
}

footer {
  background: $color_footer_bg;
  color: $color_footer_text;

  a {
    color: $color_ftnavlink;

    &:hover {
      color: $color_ftnavlink_hover;
    }
  }

  // @media (min-width: 768px) {
  //   .container {
  //     .row {
  //       nav {
  //         ul {
  //           display: inline-block;
  //         }

  //         a {
  //           display: inline-block;
  //           padding: 0.3em;
  //         }
  //       }
  //     }
  //   }
  // }
}