@charset "utf-8";
// Q&Aパーツ(Q&A parts)
.p-qa {
  .question {
    @include fontsize(16);
    border: none;
    position: relative;
    margin: 0 0 0;
    padding: 1em 50px 1em 1em;
    background-color: $color_qa_bg;
    font-weight: bold;

    &:after {
      content: none;
    }

    &:before {
      content: none;
    }

    &.open{
      &:after{
        transform: rotate(180deg);
      }
    }

    &:not(:first-child) {
      margin-top: 1em !important;
    }
  }

  .answer {
    padding: 1em 0 0 1em;
  }

  &.qa_active {
    .question {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 1em;
        left: auto;
        top: 50%;
        bottom: auto;
        margin-top: -12px;
        background: url(../../../../../wp-content/uploads/arrow_down.svg) center center no-repeat;
        background-size: 24px;
        transition: .5s;
        border: none;
      }
    }

    .answer {
      display: none;
    }
  }
}

.highlight .qa {
  $color_highlight_qa_bg: lighten($color_qa_bg, 5%);

  .question {
    background: $color_highlight_qa_bg;

    &:hover {
      background: darken($color_highlight_qa_bg, 3%);
    }

    &.open {
      &:hover {
        background: $color_highlight_qa_bg;
      }
    }
  }
}
