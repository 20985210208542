@charset "UTF-8";
@import url("https://use.fontawesome.com/releases/v5.6.1/css/all.css");
/*!
  12 COLUMN : RESPONSIVE GRID SYSTEM
  DEVELOPER : DENIS LEBLANC
  URL : http://responsive.gs
  VERSION : 3.0
  LICENSE : GPL & MIT
*/
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  max-width: 100%;
  margin: 0 auto;
}

.row {
  padding-bottom: 0;
}

.col {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .span_1 {
    width: 8.33333333333%;
  }
  .span_2 {
    width: 16.6666666667%;
  }
  .span_3 {
    width: 25%;
  }
  .span_4 {
    width: 33.3333333333%;
  }
  .span_5 {
    width: 41.6666666667%;
  }
  .span_6 {
    width: 50%;
  }
  .span_7 {
    width: 58.3333333333%;
  }
  .span_8 {
    width: 66.6666666667%;
  }
  .span_9 {
    width: 75%;
  }
  .span_10 {
    width: 83.3333333333%;
  }
  .span_11 {
    width: 91.6666666667%;
  }
  .span_12 {
    width: 100%;
  }
  .gutters .row {
    column-gap: 2%;
    row-gap: 20px;
  }
  .gutters .span_1 {
    width: 6.5%;
  }
  .gutters .span_2 {
    width: 15%;
  }
  .gutters .span_3 {
    width: 23.5%;
  }
  .gutters .span_4 {
    width: 32%;
  }
  .gutters .span_5 {
    width: 40.5%;
  }
  .gutters .span_6 {
    width: 49%;
  }
  .gutters .span_7 {
    width: 57.5%;
  }
  .gutters .span_8 {
    width: 66%;
  }
  .gutters .span_9 {
    width: 74.5%;
  }
  .gutters .span_10 {
    width: 83%;
  }
  .gutters .span_11 {
    width: 91.5%;
  }
  .gutters .span_12 {
    width: 100%;
  }
}

/*!
 12 COLUMN : RESPONSIVE GRID SYSTEM Fix Ver. 1.2.1
*/
.container {
  padding: 0 1%;
}

.container img {
  max-width: 100%;
  height: auto;
}

* + .container {
  margin-top: 2em;
}

.row:not(:last-child) {
  margin-bottom: 4%;
}

@media screen and (max-width: 767px) {
  .row:not(:last-child) {
    margin-bottom: 30px;
  }
}

.col:not(:last-child) {
  margin-bottom: 3%;
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0 10px;
  }
  * + .container {
    margin-top: 40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .row.reverse {
    flex-flow: row-reverse;
  }
  .col:not(:last-child) {
    margin-bottom: 0;
  }
  .col.offset_1 {
    margin-left: 8.33333333333%;
  }
  .col.offset_2 {
    margin-left: 16.6666666667%;
  }
  .col.offset_3 {
    margin-left: 25%;
  }
  .col.offset_4 {
    margin-left: 33.3333333333%;
  }
  .col.offset_5 {
    margin-left: 41.6666666667%;
  }
  .col.offset_6 {
    margin-left: 50%;
  }
  .col.offset_7 {
    margin-left: 58.3333333333%;
  }
  .col.offset_8 {
    margin-left: 66.6666666667%;
  }
  .col.offset_9 {
    margin-left: 75%;
  }
  .col.offset_10 {
    margin-left: 83.3333333333%;
  }
  .col.offset_11 {
    margin-left: 91.6666666667%;
  }
  .col.offset_12 {
    margin-left: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .d-flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .align-items-start {
    align-items: flex-start;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-end {
    align-items: flex-end;
  }
  .justify-content-start {
    justify-content: flex-start;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .row.col_5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.grid_16 .span_1 {
  width: 6.25%;
}

.grid_16 .span_2 {
  width: 12.5%;
}

.grid_16 .span_3 {
  width: 18.75%;
}

.grid_16 .span_4 {
  width: 25%;
}

.grid_16 .span_5 {
  width: 31.25%;
}

.grid_16 .span_6 {
  width: 37.5%;
}

.grid_16 .span_7 {
  width: 43.75%;
}

.grid_16 .span_8 {
  width: 50%;
}

.grid_16 .span_9 {
  width: 56.25%;
}

.grid_16 .span_10 {
  width: 62.5%;
}

.grid_16 .span_11 {
  width: 68.75%;
}

.grid_16 .span_12 {
  width: 75%;
}

.grid_16 .span_13 {
  width: 81.25%;
}

.grid_16 .span_14 {
  width: 87.5%;
}

.grid_16 .span_15 {
  width: 93.75%;
}

.grid_16 .span_16 {
  width: 100%;
}

.grid_16.gutters .span_1 {
  width: 4.375%;
}

.grid_16.gutters .span_2 {
  width: 10.75%;
}

.grid_16.gutters .span_3 {
  width: 17.125%;
}

.grid_16.gutters .span_4 {
  width: 23.5%;
}

.grid_16.gutters .span_5 {
  width: 29.875%;
}

.grid_16.gutters .span_6 {
  width: 36.25%;
}

.grid_16.gutters .span_7 {
  width: 42.625%;
}

.grid_16.gutters .span_8 {
  width: 49%;
}

.grid_16.gutters .span_9 {
  width: 55.375%;
}

.grid_16.gutters .span_10 {
  width: 61.75%;
}

.grid_16.gutters .span_11 {
  width: 68.125%;
}

.grid_16.gutters .span_12 {
  width: 74.5%;
}

.grid_16.gutters .span_13 {
  width: 80.875%;
}

.grid_16.gutters .span_14 {
  width: 87.25%;
}

.grid_16.gutters .span_15 {
  width: 93.625%;
}

.grid_16.gutters .span_16 {
  width: 100%;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html.scrollPrevent {
  overflow: hidden;
  height: 100%;
}

body {
  background: #fff;
  font-family: "Zen Maru Gothic", system-ui;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #1f1f1f;
  height: auto !important;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-weight: 500;
}

@media (max-width: 767px) {
  body {
    font-size: 1.6rem;
  }
  body:not(.home) p:not(.text-center):not(.sp-text-center) {
    text-align: justify;
  }
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;
}

figure img {
  max-width: 100%;
  height: auto;
}

figure figcaption {
  margin-top: 0.5em;
  text-align: left;
}

picture {
  margin: 0;
  text-align: center;
}

picture img {
  max-width: unset;
  height: auto;
}

a {
  color: #44b45c;
  transition: all .3s;
  overflow-wrap: anywhere;
}

a:hover {
  color: #44b45c;
  text-decoration: none;
}

.highlight a {
  color: #1f1f1f;
}

.highlight a:hover {
  color: #44b45c;
}

a:not([class]) img, a.swipebox img {
  transition: all .3s;
}

a:not([class]):hover img, a.swipebox:hover img {
  opacity: 0.8;
}

img {
  vertical-align: middle;
}

img.circle {
  border-radius: 50%;
}

p {
  margin: 0.5em 0 1em;
  overflow-wrap: anywhere;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.5em 0;
  padding-left: 2em;
}

ol ol:last-child,
ol ul:last-child,
ul ol:last-child,
ul ul:last-child {
  margin-bottom: 0.5em;
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-unstyled ol,
ul.list-unstyled ul {
  padding-left: 2em;
}

ul.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -7px;
  font-size: 0rem;
  margin-bottom: 16px;
}

ul.list-inline > li {
  display: inline;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  font-size: 1.6rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main .contents_wrapper {
  max-width: 100%;
  padding: 0;
}

main section {
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  main section {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  main section:last-child {
    margin-bottom: 80px;
  }
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
}

@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub .sidebar {
    letter-spacing: -.40em;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-left: 2%;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap:nth-child(2n+1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_detail:not(.reverse) .col.span_12 + .span_4,
  .article_list:not(.reverse) .col.span_12 + .span_4 {
    margin-left: 0;
  }
}

.sidebar {
  visibility: hidden;
}

.sidebar > .widget_wrap {
  visibility: visible;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 6%;
}

.sidebar ul a {
  color: #1f1f1f;
  text-decoration: none;
  display: block;
  padding: .5em;
  border-bottom: rgba(68, 180, 92, 0.1) solid 1px;
}

.sidebar ul a:hover {
  color: #44b45c;
  background: rgba(68, 180, 92, 0.05);
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.map_wide #map_canvas,
.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_wide img {
  max-width: none;
}

.slick-track {
  margin: 0 auto;
}

.col dl p {
  line-height: 1.4;
  margin: 0;
}

.col p {
  line-height: 1.8;
}

.container {
  padding: 0 20px;
}

@media (min-width: 1020px) {
  .container {
    padding: 0;
  }
}

.highlight {
  background: rgba(68, 180, 92, 0.1);
  color: #1f1f1f;
}

.highlight.round {
  border-radius: 10px;
}

section.highlight, p.highlight, .col.highlight {
  padding: 4%;
}

.js-header_break_point {
  display: none;
  z-index: 1000;
}

:root {
  --header_break_point: 1000;
}

:root {
  --navi_event_type: hover;
}

.column_main .col p + *:not(p), .column_main .col table + *:not(p) {
  margin-top: 40px;
}

.globalHeader {
  background: #fff;
  color: #1f1f1f;
  font-size: 16px;
}

@media (max-width: 999px) {
  .globalHeader.high {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 999px) {
  .globalHeader {
    background: #fff;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
}

.globalHeader .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  height: 140px;
  max-width: 100%;
}

.globalHeader .container .globalHeader_top {
  width: 98%;
  margin: 0 auto;
}

@media (max-width: 999px) {
  .globalHeader .container {
    height: 60px;
  }
}

.globalHeader .globalHeader_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.globalHeader .header_logo {
  width: 281px;
  height: 50px;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 999px) {
  .globalHeader .header_logo {
    width: 240px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .globalHeader .header_logo {
    width: 200px;
    height: auto;
  }
}

.globalHeader .header_logo a {
  display: block;
  height: auto;
  line-height: 0;
}

.globalHeader .header_logo a img {
  max-width: 100%;
  height: auto;
}

.global_nav {
  width: auto;
  list-style-position: inside;
}

@media (max-width: 999px) {
  .global_nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
    z-index: 56;
    transition: all .3s;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.global_nav > ul {
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1000px) {
  .global_nav > ul {
    margin-top: 70px;
  }
  .global_nav > ul > li > a:hover {
    color: #44b45c;
  }
}

@media (max-width: 999px) {
  .global_nav > ul {
    display: block;
    background: rgba(68, 180, 92, 0.9);
    width: 80vw;
    max-width: 400px;
    transform: translateX(100%);
    transition: all .3s;
    height: 100%;
    overflow: auto;
  }
}

@media (max-width: 999px) {
  .global_nav > ul li {
    border-bottom: #b3b3b3 solid 1px;
  }
  .global_nav > ul li:first-child {
    border-top: #b3b3b3 solid 1px;
  }
}

.global_nav > ul li a {
  color: #1f1f1f;
  text-decoration: none;
  padding: .5em 1em;
  transition: all .3s;
}

@media (min-width: 1000px) {
  .global_nav > ul li a {
    position: relative;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1060px) {
  .global_nav > ul li a {
    padding: .5em .7em;
  }
}

@media (max-width: 999px) {
  .global_nav > ul li a {
    display: block;
    padding: 1em;
    color: #fff;
    position: relative;
  }
  .global_nav > ul li a:after {
    content: '';
    width: 8px;
    height: 8px;
    border: 0;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -4px;
  }
}

.global_nav > ul li:first-child a:before {
  content: none;
}

@media (max-width: 999px) {
  .global_nav.open {
    opacity: 1;
    visibility: visible;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
}

@media (max-width: 999px) {
  .global_nav.open > ul {
    transform: translateX(0);
  }
}

/**ハンバーガーメニュー**/
#headerToggle {
  display: none;
}

@media (max-width: 999px) {
  #headerToggle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 30px;
    height: 25px;
    transition: all .3s;
    height: 19px;
    transform: translateY(-4px);
  }
  #headerToggle span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #44b45c;
    transition: all .4s;
  }
  #headerToggle span:nth-of-type(1) {
    top: 0;
  }
  #headerToggle span:nth-of-type(2) {
    top: 11px;
    top: 8px;
  }
  #headerToggle span:nth-of-type(3) {
    bottom: 0;
  }
  #headerToggle:after {
    content: 'MENU';
    position: absolute;
    top: auto;
    bottom: -16px;
    left: -100%;
    right: -100%;
    margin: auto;
    width: 100%;
    font-size: 8px !important;
    letter-spacing: -0.02em;
    color: #44b45c;
    text-align: center;
    font-weight: bold;
  }
  #headerToggle.active span {
    width: 80%;
    left: 10%;
  }
  #headerToggle.active span:nth-of-type(1) {
    transform: translateY(11px) rotate(-45deg);
    transform: translateY(8px) rotate(-45deg);
  }
  #headerToggle.active span:nth-of-type(2) {
    left: 50%;
    opacity: 0;
  }
  #headerToggle.active span:nth-of-type(3) {
    transform: translateY(-11px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
  }
  #headerToggle.active:after {
    content: 'CLOSE';
    letter-spacing: -0.1em;
  }
}

.hdr_info_wrapper {
  display: none;
}

@media (min-width: 1000px) {
  .hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
  }
}

.hdr_info_wrapper .hdr_info {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.hdr_info_wrapper .hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
}

.hdr_info_wrapper .hdr_info_group li.tel p {
  margin: 0;
  text-align: right;
  line-height: 1.2;
}

.hdr_info_wrapper .hdr_info_group li.contact a {
  max-width: 245px;
  width: 245px;
}

.sp_hdr_info_wrapper {
  display: none;
}

@media (max-width: 999px) {
  .sp_hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 15px;
    right: 70px;
  }
}

.sp_hdr_info_wrapper .sp_hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li {
  margin-left: 10px;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel a {
  color: #44b45c;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel i {
  font-size: 3.2rem;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail a {
  color: #44b45c;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail i {
  font-size: 3.2rem;
}

.home footer {
  border-top: 0;
}

footer {
  border-top: 2px solid #44b45c;
  position: relative;
  padding: 60px 0;
  font-size: 16px;
}

footer .fNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .fNav ul li a {
  display: block;
  padding: .3em .3em;
  text-decoration: none;
  position: relative;
}

footer .fNav ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: .6em;
}

footer .fNav ul li a:hover {
  text-decoration: underline;
}

footer .fNav ul li.menu-item-has-children ul {
  display: block !important;
}

footer .fNav > ul > li > a {
  padding-left: 15px;
}

footer .fNav > ul > li > a:before {
  top: .7em;
  width: 5px;
  height: 5px;
  border: 6px solid transparent;
  border-left: 6px solid #44b45c;
  border-right: 0;
}

footer .fNav > ul > li > .sub-menu > li > a:before {
  width: 6px;
  height: 2px;
  background: #44b45c;
  top: .9em;
}

footer .fNav > ul > li > .sub-menu > li .sub-menu li a:before {
  top: .8em;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #44b45c;
  border-right: 1px solid #44b45c;
  transform: rotate(-45deg);
}

footer .copy_right {
  font-size: 1rem;
}

@media (max-width: 767px) {
  footer {
    padding: 2em 0 30px;
  }
  footer .col {
    margin-bottom: 0 !important;
  }
  footer .fNav ul li {
    border-bottom: 1px solid black;
  }
  footer .fNav ul li a {
    padding: 1em .3em;
  }
  footer .fNav ul li a:before {
    top: 1.6em !important;
  }
  footer .fNav ul li .sub-menu li:first-child {
    border-top: 1px solid black;
  }
  footer .fNav ul li .sub-menu li:last-child {
    border: 0;
  }
  footer .fNav > ul > li > a {
    padding-left: 15px;
  }
  footer .fNav > ul > li > .sub-menu > li > a {
    padding-left: 30px;
  }
  footer .fNav > ul > li > .sub-menu > li > a:before {
    left: 15px;
  }
  footer .fNav > ul > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px;
  }
  footer .fNav > ul > li > .sub-menu > li > .sub-menu > li > a:before {
    left: 30px;
  }
}

@media (min-width: 768px) {
  footer .fNav {
    margin-bottom: 5%;
  }
  footer .fNav > ul > li .sub-menu {
    padding-left: 1em;
  }
  footer .fNav > ul > li .sub-menu > li > a {
    padding-left: 15px;
  }
}

.copy_right {
  background: #fff;
  color: #1f1f1f;
  font-size: 1.6rem;
  margin: 0;
  padding: 2em 0;
  border-top: 1px solid white;
}

@media (max-width: 767px) {
  .copy_right {
    padding: 1em 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li.tablet-break:after {
    content: "\A";
    white-space: pre;
  }
  footer nav ul li.tablet-break + li:before {
    display: none;
  }
}

footer {
  background: #fff;
  color: #1f1f1f;
}

footer a {
  color: #1f1f1f;
}

footer a:hover {
  color: #1f1f1f;
}

.main_visual {
  position: relative;
}

@media (max-width: 999px) {
  .main_visual {
    margin-top: 60px;
  }
}

.main_visual .main_visual_slick, .main_visual .slick-track {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100% !important;
}

.main_visual .slick-track {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.main_visual .slick-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.main_visual .main_visual_slick {
  position: relative;
  margin: 0 auto;
  opacity: 0;
  transition: 0.1s;
}

.main_visual .main_visual_slick.slick-initialized {
  opacity: 1;
}

.main_visual .main_visual_slick .slick-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.main_visual .main_visual_slick .slick-slide picture, .main_visual .main_visual_slick .slick-slide img {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
  top: 0;
  width: auto;
  height: 100%;
}

.main_visual .main_visual_copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_copy > li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.main_visual .main_visual_copy > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 67%;
  max-width: 670px;
  max-height: 153px;
  z-index: 52;
  right: auto;
  left: 10px;
}

@media (max-width: 767px) {
  .main_visual .main_visual_copy > li img {
    width: 90%;
    bottom: auto;
    top: 10%;
  }
}

.main_visual .main_visual_slick {
  padding-top: 54%!important;
}

@media (min-width: 1000px) {
  .main_visual .main_visual_slick {
    padding-top: 0 !important;
    height: 540px !important;
  }
}

@media (min-width: 1920px) {
  .main_visual .main_visual_slick {
    padding-top: 28.125%!important;
  }
}

@media (max-width: 767px) {
  .main_visual .main_visual_slick {
    padding-top: 93.33333%!important;
  }
}

.slick-dots {
  z-index: 55 !important;
  bottom: -50px;
}

.slick-dots li button {
  margin: 0 auto;
  background: #ccc;
}

.slick-dots li.slick-active button {
  background: #44b45c;
}

.main_visual .main_visual_swiper {
  opacity: 0;
  transition: 0.1s;
}

.main_visual .main_visual_swiper.swiper-container-initialized {
  opacity: 1;
}

.main_visual .main_visual_swiper .swiper-slide {
  position: relative;
}

.main_visual .main_visual_swiper .swiper-slide a {
  color: white;
}

.main_visual .main_visual_swiper .swiper-slide .ofi {
  padding-top: 700px;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3 {
  color: white;
  padding: 0;
  margin: 0 0 .5em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3:after {
  content: none;
}

.main_visual .swiper-button-prev, .main_visual .swiper-button-next {
  background: #44b45c;
  color: white;
  width: 60px;
  height: 60px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev, .main_visual .swiper-button-next {
    width: 40px;
    height: 40px;
  }
}

.main_visual .swiper-button-prev:after, .main_visual .swiper-button-next:after {
  font-size: 16px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev:after, .main_visual .swiper-button-next:after {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev {
    left: 0;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-next {
    right: 0;
  }
}

.main_visual .swiper-pagination {
  position: static;
  margin: 1em 0;
}

.main_visual .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #44b45c;
  margin: 0 5px;
  outline-color: transparent;
}

.h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  padding: .7em 5%;
  color: #1f1f1f;
  background: url("/wp-content/uploads/h1_bg.png");
  margin: 0 0 40px;
}

@media (max-width: 767px) {
  .h1 {
    margin-bottom: 10px;
  }
}

@media (max-width: 999px) {
  .h1 {
    margin-top: 60px;
  }
}

.h1 span {
  display: block;
  font-size: 1.6rem;
  padding-top: .5em;
  color: #393939;
  font-style: italic;
}

.h1 a {
  text-decoration: none;
}

.h1 a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .h1 {
    font-size: 2.4rem;
  }
  .h1 span {
    font-size: 1.4rem;
  }
}

.h2, .article_list h2 {
  display: block;
  margin: 0 auto 1em;
  position: relative;
  text-align: center;
  border: 0;
  font-weight: bold;
  padding: 0 0 .5em;
  font-size: 3rem;
  color: #1f1f1f;
}

.h2 a, .article_list h2 a {
  text-decoration: none;
  color: #1f1f1f;
}

.h2 a:hover, .article_list h2 a:hover {
  text-decoration: underline;
  color: #1f1f1f;
}

.h2 span, .article_list h2 span {
  display: block;
  font-size: 1.4rem;
  padding-top: 1em;
}

.h2:after, .article_list h2:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
  margin: auto;
  background: #44b45c;
}

@media (max-width: 767px) {
  .h2, .article_list h2 {
    font-size: 2.4rem;
  }
  .h2:after, .article_list h2:after {
    width: 20px;
  }
}

.h3 {
  margin: 0 0 1em;
  padding-bottom: 0.5em;
  color: #1f1f1f;
  font-size: 2.4rem;
  position: relative;
}

.h3 a {
  text-decoration: none;
}

.h3 a:hover {
  text-decoration: underline;
}

.h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(68, 180, 92, 0.6);
}

@media (max-width: 767px) {
  .h3 {
    font-size: 2rem;
  }
}

.h4 {
  margin: 0 0 1em;
  padding-left: 1em;
  font-size: 1.8rem;
  position: relative;
  color: #1f1f1f;
  font-weight: bold;
}

.h4 a {
  text-decoration: none;
}

.h4 a:hover {
  text-decoration: underline;
}

.h4:after {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 1em;
  margin: auto;
  background: #44b45c;
}

@media (max-width: 767px) {
  .h4 {
    font-size: 1.4rem;
  }
}

.widget_wrap .widget_header {
  font-size: 1.6rem;
  color: #1f1f1f;
  padding: .5em 0 .5em .5em;
  border-bottom: #44b45c solid 2px;
  font-weight: bold;
}

.widget_wrap .widget_header a {
  text-decoration: none;
}

.widget_wrap .widget_header a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .widget_wrap .widget_header {
    font-size: 1.4rem;
  }
}

.bread {
  overflow: hidden;
  font-size: 1.2rem;
  margin: 0 auto 60px;
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 767px) {
  .bread {
    margin: 0 auto 40px;
  }
}

.bread ul {
  padding: 1em 0;
  margin: 0 auto;
  float: none;
}

.bread ul li {
  list-style: none;
  position: relative;
  float: left;
  padding-right: 2em;
  margin-right: 1.5em;
}

.bread ul li a {
  text-decoration: none;
  color: #44b45c;
}

.bread ul li a:hover {
  color: #44b45c;
  text-decoration: none;
}

.bread ul li:after {
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.bread ul li:last-child:after {
  content: none;
}

.button, .inquiry .form_button {
  display: inline-block;
  max-width: 220px;
  width: 100%;
  margin: 0;
  padding: .9em 1em 1.1em;
  border: #44b45c solid 1px;
  border-radius: 0px;
  line-height: 1;
  background: #44b45c;
  color: white;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 40px;
  /*
* ボタン矢印
*/
}

.button:hover, .inquiry .form_button:hover {
  background: #368f49;
  color: white;
  border-color: #368f49;
}

.button.arrow, .inquiry .arrow.form_button {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.4;
}

.button.arrow:after, .inquiry .arrow.form_button:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 46%;
  right: 5%;
  margin: auto;
  width: 5px;
  height: 5px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(-45deg);
  transition: 0.2s;
}

.button.arrow:hover:after, .inquiry .arrow.form_button:hover:after {
  right: 4%;
}

.highlight .button, .highlight .inquiry .form_button, .inquiry .highlight .form_button {
  color: white;
}

.highlight .button:hover, .highlight .inquiry .form_button:hover, .inquiry .highlight .form_button:hover {
  color: white;
}

.button.inverse, .inquiry .inverse.form_button {
  background: none;
  border-color: #44b45c;
  color: #44b45c;
}

.button.inverse:after, .inquiry .inverse.form_button:after {
  border-color: #44b45c;
}

.button.inverse:hover, .inquiry .inverse.form_button:hover {
  background: #44b45c;
  color: #fff;
}

.button.inverse:hover:after, .inquiry .inverse.form_button:hover:after {
  border-color: #fff;
}

.button.large, .inquiry .large.form_button {
  max-width: max-content;
  padding-left: 2em;
  padding-right: 2em;
}

.button.block, .inquiry .block.form_button {
  display: block;
  min-width: 100%;
}

.button.small, .inquiry .small.form_button {
  padding: .6em 1em;
  max-width: unset;
  width: auto;
}

.button.small.arrow, .inquiry .small.arrow.form_button {
  padding-right: 2.5em;
}

.button + .button, .inquiry .form_button + .button, .inquiry .button + .form_button, .inquiry .form_button + .form_button {
  margin-left: 0.5em;
}

@media (max-width: 767px) {
  .button + .button, .inquiry .form_button + .button, .inquiry .button + .form_button, .inquiry .form_button + .form_button {
    margin-left: 0;
    margin-top: 1em;
  }
}

/* お問い合わせフォーム */
.inquiry .h-adr {
  margin: 0;
  padding: 4%;
  border: rgba(68, 180, 92, 0.1) solid 5px;
  border-radius: 10px;
}

.inquiry .h-adr .p-country-name {
  display: none;
}

.inquiry .table-default {
  border: none;
}

.inquiry .table-default tr:not(:last-of-type) {
  border-bottom: 1px solid #cccccc;
}

.inquiry .table-default th {
  background: none;
  border: none;
  text-align: left;
  padding: 1.4em 50px 1.2em .5em;
  vertical-align: top;
  font-weight: bold;
  position: relative;
}

@media (max-width: 767px) {
  .inquiry .table-default th {
    padding: 1em 0 0;
  }
}

.inquiry .table-default th .required {
  display: inline-block;
  background: #d43f3a;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
  margin-left: .5em;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .inquiry .table-default th .required {
    position: absolute;
    top: 1.6em;
    right: 0;
  }
}

.inquiry .table-default td {
  background: none;
  border: none;
  padding: 1.2em .5em;
}

@media (max-width: 767px) {
  .inquiry .table-default td {
    padding: .5em 0 1em;
  }
}

.inquiry .input_wrap {
  display: block;
  margin: 0;
  padding: 0 .2em;
}

@media (max-width: 767px) {
  .inquiry .input_wrap {
    margin: 0;
  }
}

.inquiry .input_wrap input, .inquiry .input_wrap textarea {
  margin: 0;
}

.inquiry .input_wrap:last-child {
  margin-right: auto;
}

.inquiry .text-2col {
  display: flex;
}

.inquiry .text-2col .input_wrap {
  flex-basis: 50%;
}

.inquiry input[type="text"],
.inquiry input[type="url"],
.inquiry input[type="email"],
.inquiry input[type="tel"],
.inquiry input[type="date"],
.inquiry input[type="number"],
.inquiry select,
.inquiry textarea {
  width: 100%;
  max-width: 100%;
  padding: 7px 2%;
  color: #1f1f1f;
  font-size: 1.6rem;
  margin: 0 .2em;
  border: 1px solid #cccccc;
  background: #fafafa;
  border-radius: 3px;
  -webkit-appearance: none;
}

@media (max-width: 767px) {
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="number"],
  .inquiry select,
  .inquiry textarea {
    margin: .2em 0;
  }
}

.inquiry input[type="number"] {
  width: 65px;
  text-align: center;
}

.inquiry input.p-postal-code {
  width: 200px;
}

.inquiry input.p-region {
  width: 100px;
}

.inquiry input.p-locality {
  width: 150px;
}

.inquiry input.p-street-address {
  width: 100%;
}

.inquiry input.p-extended-address {
  width: 100%;
}

.inquiry .mwform-tel-field {
  display: flex;
  justify-content: space-between;
}

.inquiry .submit {
  margin: 1em 0;
  text-align: center;
}

.inquiry .form_button, .inquiry .wpcf7-submit {
  background: #44b45c;
  color: white;
  border-color: #44b45c;
}

.inquiry .form_button.form_back, .inquiry .wpcf7-submit.form_back {
  background: #ccc;
  border-color: #ccc;
  color: #666;
}

.inquiry .form_button.form_back:hover, .inquiry .wpcf7-submit.form_back:hover {
  background: #bfbfbf;
  border-color: #bfbfbf;
  color: #666;
}

.inquiry .form_button.form_send, .inquiry .wpcf7-submit.form_send {
  background: #368f49;
  border-color: #368f49;
  color: #fff;
}

.inquiry .form_button.form_send:hover, .inquiry .wpcf7-submit.form_send:hover {
  background: #2f7c40;
  border-color: #2f7c40;
  color: #fff;
}

@media (min-width: 768px) {
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="text"],
  .inquiry select {
    margin: 0 .2em;
  }
  .inquiry input[type="number"] {
    width: 65px;
  }
  .inquiry input.p-postal-code {
    width: 200px;
  }
  .inquiry input.p-region {
    width: 100px;
  }
  .inquiry input.p-locality {
    width: 150px;
  }
  .inquiry input.p-street-address {
    width: 100%;
  }
  .inquiry input.p-extended-address {
    width: 100%;
  }
  .inquiry textarea {
    width: 100%;
  }
  .inquiry .input_wrap {
    display: block;
    margin: 0 .2em;
  }
  .inquiry .input_wrap input, .inquiry .input_wrap textarea {
    margin: 0;
  }
  .inquiry .input_wrap:last-child {
    margin-right: auto;
  }
  .inquiry .submit {
    margin: 60px 0 0;
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .form_button_wrap {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.form_button_wrap button {
  margin: .5em !important;
}

.wpcf7-spinner {
  margin: 1em 24px !important;
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none !important;
}

.mw_wp_form_input .input-hide {
  display: none !important;
}

.mw_wp_form .error {
  color: #d43f3a !important;
}

.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2;
  /* Blue */
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450;
  /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232;
  /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28;
  /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #ffb900;
  /* Yellow */
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  color: #dc3232;
  /* Red */
  font-size: 1em;
  font-weight: normal;
  display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: .2em .8em;
  width: 24em;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " ";
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  /* Dark Gray 800 */
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: '';
  position: absolute;
  background-color: #fbfbfc;
  /* Light Gray 100 */
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
  .wpcf7-spinner::before {
    animation-name: blink;
    animation-duration: 2000ms;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.wpcf7 [inert] {
  opacity: 0.5;
}

.wpcf7 input[type="file"] {
  cursor: pointer;
}

.wpcf7 input[type="file"]:disabled {
  cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed;
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr;
}

.wpcf7-reflection > output {
  display: list-item;
  list-style: none;
}

.wpcf7-reflection > output[hidden] {
  display: none;
}

body.confirm .inquiry .table-default td {
  pointer-events: none;
}

.p-loopSlide {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.p-loopSlide ul {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .p-loopSlide ul {
    width: 300%;
  }
}

.p-loopSlide ul:first-child {
  animation: loop 50s -25s linear infinite;
}

.p-loopSlide ul:last-child {
  animation: loop2 50s linear infinite;
}

.p-loopSlide ul li {
  width: 100%;
  list-style: none;
  padding: 0 0 0 20px;
}

.p-loopSlide ul li img {
  width: 100%;
  height: auto;
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

header .global_nav > ul > li.menu-item-has-children {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header .global_nav > ul > li.menu-item-has-children > a {
  position: relative;
}

@media (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children > a {
    width: calc(100% - 49px);
  }
}

header .global_nav > ul > li.menu-item-has-children > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 1em;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(0, -50%) rotate(135deg);
  transition: .2s;
}

@media (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children > a:after {
    content: none;
  }
}

header .global_nav > ul > li.menu-item-has-children.menu_under_open > a:after {
  transform: translate(0, 0) rotate(-45deg);
}

header .global_nav > ul > li > .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu {
    width: 100%;
    display: none;
  }
}

header .global_nav > ul > li > .sub-menu > li {
  border-bottom: 1px solid #b3b3b3;
}

header .global_nav > ul > li > .sub-menu > li:last-child {
  border-bottom: none;
}

header .global_nav > ul > li > .sub-menu > li > a {
  background: rgba(102, 197, 122, 0.9);
  padding-left: 2em;
  position: relative;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a {
  position: relative;
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a {
    width: calc(100% - 49px);
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    transition: .2s;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > a:after {
    content: none;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children.menu_under_open > a:after {
    transform: translate(0, 0) rotate(-45deg);
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    display: none;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
  }
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu {
    width: 100%;
    display: none;
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:not(:last-child) {
  border-bottom: #fff solid 1px;
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:not(:last-child) {
    border-bottom: #b3b3b3 solid 1px;
  }
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li:last-child {
  border-bottom: none;
}

header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li a {
  background: rgba(120, 204, 138, 0.9);
}

@media (max-width: 999px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu li a {
    padding-left: 2.5em;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li > .sub-menu > li.menu-item-has-children:hover > .sub-menu, header .global_nav > ul > li > .sub-menu > li.menu-item-has-children.menu_under_open > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
}

.child-menu-button {
  position: relative;
  width: 49px;
  min-height: 49px;
  background: #000;
  border: 0;
  outline: 0;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
}

@media (min-width: 1000px) {
  .child-menu-button {
    display: none;
  }
}

.child-menu-button:before, .child-menu-button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  background: #fff;
  transition: .2s ease;
}

.child-menu-button:before {
  width: 15px;
  height: 1px;
}

.child-menu-button:after {
  width: 1px;
  height: 15px;
}

.child-menu-button.is-open:before {
  opacity: 0;
  transform: rotate(90deg);
}

.child-menu-button.is-open:after {
  transform: rotate(90deg);
}

.child-menu-button.is-open + .sub-menu {
  opacity: 1 !important;
  visibility: visible !important;
  display: none;
}

@media (min-width: 1000px) {
  header .global_nav > ul > li.menu-item-has-children {
    position: relative;
    cursor: pointer;
  }
  header .global_nav > ul > li.menu-item-has-children > a {
    position: relative;
  }
  header .global_nav > ul > li.menu-item-has-children > a:after {
    border: 4px solid transparent;
    border-top: 6px solid #44b45c;
    border-bottom: 0;
    width: 6px;
    height: 6px;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    transform: translate(0, -50%);
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    top: 100%;
    min-width: 200px;
    width: max-content;
    letter-spacing: -.40em;
    z-index: 999;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li {
    letter-spacing: normal;
    display: block;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li:not(:last-child) {
    border-bottom: white solid 1px;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li a {
    display: block;
    background: rgba(68, 180, 92, 0.9);
    padding: .5em;
    color: #fff;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li a:before {
    content: none;
  }
  header .global_nav > ul > li.menu-item-has-children > .sub-menu > li a:hover {
    background: #286a36;
  }
}

@media (min-width: 1000px) and (max-width: 999px) {
  header .global_nav > ul > li.menu-item-has-children {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1000px) {
  header .global_nav > ul > li.menu-item-has-children:hover .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  header .global_nav > ul > li.menu-item-has-children:hover > ul {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
}

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #44b45c;
}

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.movie.ar16to9 {
  padding-top: 56.25%;
}

.movie.ar4to3 {
  padding-top: 75%;
}

@media (max-width: 767px) {
  .news .heading {
    display: flex;
    justify-content: space-between;
  }
  .news .heading h2 {
    text-align: left;
    margin: 0 0 1em;
  }
  .news .heading h2:after {
    right: auto;
  }
}

.news dl {
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
}

.news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  border-bottom: #ccc solid 1px;
  position: relative;
}

.news dl dd a {
  text-decoration: none;
}

.news dl dd a:hover {
  text-decoration: underline;
}

.news dl .badge_category {
  display: inline-block;
  background: #44b45c;
  color: white;
  padding: .2em 1em;
  margin-left: 1em;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .news > div {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  .news > div .heading {
    width: 23.5%;
  }
  .news h2 {
    margin: 0;
    padding: 0;
  }
  .news h2:after {
    display: none;
  }
  .news .content {
    overflow: hidden;
    width: 76.5%;
    padding-left: 2%;
  }
  .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .news dl dt {
    margin: 0;
    padding: 1em 0 .5em 0;
  }
  .news dl dd {
    margin: 0;
    padding: .5em 0 1em 0;
  }
}

.pagetop {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 98;
}

.pagetop a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: clamp(50px, 10vw, 100px);
  height: clamp(50px, 10vw, 100px);
  padding: 0;
  background: url("/wp-content/uploads/pagetop.png") no-repeat center/cover;
}

footer.high .pagetop {
  position: absolute;
  bottom: auto;
  right: 5%;
  top: 0;
  margin: 0;
  transform: translateY(-50%);
}

.p-qa .question {
  font-size: 1.6rem;
  border: none;
  position: relative;
  margin: 0 0 0;
  padding: 1em 50px 1em 1em;
  background-color: rgba(68, 180, 92, 0.1);
  font-weight: bold;
}

.p-qa .question:after {
  content: none;
}

.p-qa .question:before {
  content: none;
}

.p-qa .question.open:after {
  transform: rotate(180deg);
}

.p-qa .question:not(:first-child) {
  margin-top: 1em !important;
}

.p-qa .answer {
  padding: 1em 0 0 1em;
}

.p-qa.qa_active .question {
  cursor: pointer;
}

.p-qa.qa_active .question:hover {
  opacity: 0.8;
}

.p-qa.qa_active .question:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 1em;
  left: auto;
  top: 50%;
  bottom: auto;
  margin-top: -12px;
  background: url(../../../../../wp-content/uploads/arrow_down.svg) center center no-repeat;
  background-size: 24px;
  transition: .5s;
  border: none;
}

.p-qa.qa_active .answer {
  display: none;
}

.highlight .qa .question {
  background: rgba(83, 190, 106, 0.1);
}

.highlight .qa .question:hover {
  background: rgba(72, 186, 97, 0.1);
}

.highlight .qa .question.open:hover {
  background: rgba(83, 190, 106, 0.1);
}

.nav_search {
  display: none;
}

.nav_search_box {
  display: none;
}

.p-search .article_list {
  padding: 0;
  border-bottom: rgba(68, 180, 92, 0.1) solid 1px !important;
  border-top: none;
}

.p-search .article_list:first-of-type {
  border-top: rgba(68, 180, 92, 0.1) solid 1px;
}

.p-search .article_list .col {
  margin: 0;
}

.p-search .article_list .search_item {
  display: block;
  text-decoration: none;
  color: currentColor;
  padding: 1em;
}

.p-search .article_list .search_item:hover {
  opacity: 0.8;
  background: rgba(68, 180, 92, 0.03);
}

.p-search .article_list .search_item h2 {
  margin: 0 0 0 !important;
  padding-bottom: 0 !important;
  text-align: left;
}

.p-search .article_list .search_item h2:after {
  content: none;
}

.p-search .article_list .search_item h2 ~ p {
  margin-top: .6em;
}

.shopinfo figure, .shopinfo picture {
  max-width: 80%;
  margin: 0 auto;
}

.shopinfo address {
  text-align: center;
  font-size: 2rem;
  font-style: normal;
}

.shopinfo dl {
  margin: 2em 0;
  line-height: 1.4;
}

.shopinfo dl:first-child {
  margin-top: 0;
}

.shopinfo dl dt {
  margin: 0 0 0.2em 0;
  font-weight: normal;
}

.shopinfo dl dt:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 0.3em;
  vertical-align: middle;
  background: #44b45c;
}

.shopinfo dl dd {
  margin: 0 0 0.5em 1em;
  padding: 0;
}

.map_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_container + p {
  margin-top: 2em;
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.scroll .googlemap {
  pointer-events: none;
}

@media (min-width: 768px) {
  .shopinfo figure, .shopinfo picture {
    max-width: 100%;
  }
  .shopinfo address {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2.6rem;
    line-height: 1;
  }
  .shopinfo dl.dl-horizontal {
    margin: 10px 0;
  }
  .shopinfo dl.dl-horizontal dd {
    margin-bottom: 0.3em;
  }
  .shopinfo .span12 .map_container {
    padding-top: 50%;
  }
  .map_wide {
    padding-top: 420px;
  }
}

.p-sitemap {
  border-top: rgba(31, 31, 31, 0.3) solid 1px;
}

.p-sitemap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-sitemap ul li a {
  display: block;
  text-decoration: none;
  position: relative;
  padding: .6em 1em .6em 1.5em;
  border-bottom: rgba(31, 31, 31, 0.3) solid 1px;
  font-size: 1.8rem;
}

.p-sitemap ul li a:before {
  content: '';
  width: 6px;
  height: 6px;
  border: 0;
  border-top: solid 2px #1f1f1f;
  border-right: solid 2px #1f1f1f;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: .5em;
  margin: auto;
}

.p-sitemap ul li a:hover {
  background: rgba(68, 180, 92, 0.1);
}

.p-sitemap ul.children {
  margin: 0;
}

.p-sitemap ul.children li a {
  padding-left: 3em;
  font-size: 1.5rem;
}

.p-sitemap ul.children li a:before {
  left: 2em;
}

.sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0rem;
  margin: auto;
}

.sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 1.6rem;
}

.sns_icons li {
  display: inline-block;
}

.sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.sns_icons a.icon_facebook {
  background-image: url(../../../../../wp-content/uploads/icon_facebook.png);
}

.sns_icons a.icon_twitter {
  background-image: url(../../../../../wp-content/uploads/icon_twitter.png);
}

.sns_icons a.icon_line {
  background-image: url(../../../../../wp-content/uploads/icon_line.png);
}

.sns_icons a.icon_google {
  background-image: url(../../../../../wp-content/uploads/icon_google.png);
}

.sns_icons a.icon_instagram {
  background-image: url(../../../../../wp-content/uploads/icon_instagram.png);
}

footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .sns_icons {
    margin: 0;
  }
  .sns_icons.center {
    margin: auto;
  }
  .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .sns_icons a {
    width: 68px;
    height: 68px;
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}

@use "sass:math";
@media (max-width: 767px) {
  .sp_image_cols {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 20px;
  }
  .sp_image_cols.sp_col2 .col {
    width: 49%;
  }
  .sp_image_cols.sp_col3 {
    display: flex;
    flex-wrap: wrap;
  }
  .sp_image_cols.sp_col3 .col {
    width: 33.3333333333%;
    padding: 0 1%;
  }
}

.p-step .row:not(:last-child) {
  position: relative;
  margin-bottom: 40px;
}

.p-step .row:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  background: #44b45c;
  height: 20px;
  width: 60px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

table {
  margin: 0 auto;
}

table td,
table th {
  padding: 0.7em 1em;
}

@media (max-width: 767px) {
  table td,
  table th {
    padding: 0.7em .3em;
  }
}

table th {
  text-align: center;
  font-weight: normal;
}

.table-default {
  width: 100%;
  table-layout: fixed;
}

.table-default thead th, .table-default thead td {
  background: rgba(68, 180, 92, 0.15);
}

.table-default td,
.table-default th {
  border: 1px solid #ccc;
}

.table-default th {
  background: rgba(204, 204, 204, 0.2);
  color: #1f1f1f;
}

.table-default td {
  background: transparent;
}

.table-default.table-mini td,
.table-default.table-mini th {
  padding: .5em 1em;
}

@media (max-width: 767px) {
  .table-default.table-mini td,
  .table-default.table-mini th {
    padding: .5em .3em;
  }
}

.table-menu {
  width: 100%;
}

.table-menu caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 2rem;
}

.table-menu td,
.table-menu th {
  padding: 0.7em 1em;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 767px) {
  .table-menu td,
  .table-menu th {
    padding: 0.7em .3em;
  }
}

.table-menu tbody th {
  text-align: left;
}

.table-menu td:last-child {
  text-align: right;
  white-space: nowrap;
}

.table-schedule {
  width: 100%;
}

.table-schedule caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 2rem;
}

.table-schedule td,
.table-schedule th {
  border: 1px solid #ccc;
  padding: 0.7em 1em;
  text-align: center;
}

@media (max-width: 767px) {
  .table-schedule td,
  .table-schedule th {
    padding: 0.7em 0.3em;
  }
}

.table-schedule thead th {
  background: rgba(204, 204, 204, 0.2);
}

.table-schedule tbody th {
  background: rgba(204, 204, 204, 0.2);
}

.table-schedule td {
  background: transparent;
}

@media (max-width: 767px) {
  .responsive-stack {
    display: block;
  }
  .responsive-stack thead {
    display: none;
  }
  .responsive-stack tbody,
  .responsive-stack td,
  .responsive-stack th,
  .responsive-stack tr {
    display: block;
  }
  .responsive-stack td,
  .responsive-stack th {
    width: 100% !important;
  }
  .responsive-stack tr:not(:last-child) td,
  .responsive-stack tr:not(:last-child) th {
    border-bottom: 0;
  }
  .responsive-stack tr:last-child :not(:last-child) {
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: 0.01%;
    overflow-x: scroll;
  }
  .responsive-scroll-container .responsive-scroll-inner {
    width: 798px;
    padding: 0 15px 15px;
  }
  .responsive-scroll-container table {
    width: 768px;
    margin: 0;
  }
}

.js-scrollable .scroll-hint-icon-wrap {
  margin-top: unset !important;
}

@media (max-width: 767px) {
  .js-scrollable table {
    width: 768px;
  }
}

table.table-default.typeA tr td,
table.table-default.typeA tr th {
  border: 0;
}

table.table-default.typeA tr:nth-child(odd) th {
  background: #44b45c;
}

table.table-default.typeA tr:nth-child(odd) td {
  background: rgba(68, 180, 92, 0.1);
}

table.table-default.typeA tr:nth-child(even) th {
  background: #66c57a;
}

table.table-default.typeA tr:nth-child(even) td {
  background: rgba(102, 197, 122, 0.1);
}

table.table-default.typeB {
  border-spacing: 0;
  overflow: hidden;
}

table.table-default.typeB tbody td,
table.table-default.typeB tbody th {
  background: transparent;
  color: #1f1f1f;
  border: 0;
  border-bottom: 1px solid rgba(204, 204, 204, 0.1);
}

table.table-default.typeB tbody tr {
  position: relative;
}

table.table-default.typeB tbody th {
  color: #44b45c;
}

table.table-default.typeC th {
  background: rgba(204, 204, 204, 0.2);
  color: #1f1f1f;
}

table.table-default.typeC td,
table.table-default.typeC th {
  border: 0;
}

table.table-default.typeC td:not(:last-child),
table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px #fff solid;
}

table.table-default.typeC tr {
  position: relative;
}

table.table-default.typeC td {
  transform: translateX(2px);
}

.highlight table.table-default.typeC td,
.highlight table.table-default.typeC th {
  border: 0;
}

.highlight table.table-default.typeC td:not(:last-child),
.highlight table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px rgba(68, 180, 92, 0.1) solid;
}

.article_list {
  padding: .5em;
  border-top: rgba(68, 180, 92, 0.2) solid 1px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .article_list {
    padding: 1em;
  }
}

.article_list .span_12 {
  margin: 2% 0;
}

.article_list:last-of-type {
  border-bottom: rgba(68, 180, 92, 0.2) solid 1px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .article_list:last-of-type {
    margin-bottom: 40px;
  }
}

.article_list .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_list .post_header .category_badge {
  background: #44b45c;
  color: white;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_list .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.article_list h2 {
  text-align: left;
  margin: 0 0 .5em !important;
  padding: 0;
  font-size: 2.4rem;
}

@media (max-width: 767px) {
  .article_list h2 {
    font-size: 1.9rem;
  }
}

.article_list h2:after {
  content: none;
}

.article_list h2 a:hover {
  text-decoration: none;
  color: #44b45c;
}

.m-pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .m-pagenation {
    margin-bottom: 50px;
  }
}

.m-pagenation .m-pagenation__prev {
  margin-right: .5em;
}

.m-pagenation .m-pagenation__prev a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__prev a:hover {
  background: rgba(68, 180, 92, 0.1);
  border-color: rgba(68, 180, 92, 0.1);
}

.m-pagenation .m-pagenation__next {
  margin-left: .5em;
}

.m-pagenation .m-pagenation__next a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__next a:hover {
  background: rgba(68, 180, 92, 0.1);
  border-color: rgba(68, 180, 92, 0.1);
}

.m-pagenation .m-pagenation__body {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.m-pagenation .m-pagenation__body li {
  display: inline-block;
  margin: 0 5px;
}

.m-pagenation .m-pagenation__body li a {
  text-decoration: none;
  display: block;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__body li a:hover {
  background: rgba(68, 180, 92, 0.1);
  border-color: rgba(68, 180, 92, 0.1);
}

.m-pagenation .m-pagenation__body li.current {
  background: #44b45c;
  color: white;
  border: #44b45c solid 1px;
  padding: .3em 1em;
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .wp-pagenavi {
    margin-bottom: 50px;
  }
}

.wp-pagenavi a, .wp-pagenavi span {
  display: inline-block;
  margin: 0 5px;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
  color: #44b45c;
}

.wp-pagenavi a.current, .wp-pagenavi span.current {
  background: #44b45c;
  color: white;
  border: #44b45c solid 1px;
  padding: .3em 1em;
}

.wp-pagenavi a:hover {
  background: rgba(68, 180, 92, 0.1);
  border-color: rgba(68, 180, 92, 0.1);
}

.article_single01 .article_single01_02 {
  margin-top: 10px;
}

.article_single01 .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single01 .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single01 .ofi {
  background: rgba(68, 180, 92, 0.1);
}

.article_single01 .more {
  display: block;
  text-align: center;
}

.article_single02 .article_single02_01 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single02 .article_single02_01 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single02 .ofi {
  background: rgba(68, 180, 92, 0.1);
}

.article_single02 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_6 {
    width: 45%;
    margin-left: 10%;
  }
  .article_single03 .article_single03_02 .span_6:first-child {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_12 {
    margin-left: 0;
    margin-top: 20px;
  }
}

.article_single03 .article_single03_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single03 .article_single03_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single03 .case_arrow {
  position: relative;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow {
    margin-bottom: 10%;
  }
}

.article_single03 .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 108%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #44b45c;
  border-right: 3px solid #44b45c;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow:after {
    left: 0;
    right: 0;
    top: 105%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_single03 .ofi {
  background: rgba(68, 180, 92, 0.1);
}

.article_single03 .more {
  display: block;
  text-align: center;
}

.article_single04 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article_single04 .article_single04_01_img1 .ofi {
  padding-top: 75%;
}

.article_single04 .article_single04_01_img2 .ofi {
  padding-top: 15%;
}

.article_single04 .article_single04_01_img3 .ofi {
  padding-top: 10.8%;
}

.article_single04 .article_single04_01_img4 .ofi {
  padding-top: 8.4%;
}

.article_single04 .article_single04_01_img5 .ofi {
  padding-top: 6.9%;
}

.article_single04 .article_single04_01_img6 .ofi {
  padding-top: 5.8%;
}

.article_single04 .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04 .article_single04_01_nav .ofi {
  padding-top: 11.1%;
}

.article_single04 .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04 .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04 .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04.article_container .article_single04_01_img1 .ofi {
  padding-top: 74.5%;
}

.article_single04.article_container .article_single04_01_img2 .ofi {
  padding-top: 18.7%;
}

.article_single04.article_container .article_single04_01_img3 .ofi {
  padding-top: 14.9%;
}

.article_single04.article_container .article_single04_01_img4 .ofi {
  padding-top: 12.5%;
}

.article_single04.article_container .article_single04_01_img5 .ofi {
  padding-top: 10.7%;
}

.article_single04.article_container .article_single04_01_img6 .ofi {
  padding-top: 9.4%;
}

.article_single04.article_container .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04.article_container .article_single04_01_nav .ofi {
  padding-top: 12%;
}

.article_single04.article_container .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04.article_container .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04.article_container .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04 .article_single04_02 {
  margin-top: 10px;
}

.article_single04 .article_single04_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single04 .article_single04_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single04 .slick-next,
.article_single04 .slick-prev {
  z-index: 1;
}

.article_single04 .slick-next:before,
.article_single04 .slick-prev:before {
  content: none;
}

.article_single04 .slick-next:after,
.article_single04 .slick-prev:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #44b45c;
  transition: 0.2s;
  z-index: 1;
}

.article_single04 .slick-prev {
  left: 0;
}

.article_single04 .slick-prev:after {
  left: 0;
  border-left: 3px solid #44b45c;
  transform: translate(0, -50%) rotate(-45deg);
  transform-origin: right top;
}

.article_single04 .slick-next {
  right: 0;
}

.article_single04 .slick-next:after {
  right: 0;
  border-right: 3px solid #44b45c;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
}

.article_single04 .ofi {
  background: rgba(68, 180, 92, 0.1);
  height: auto !important;
}

.article_single04 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_list_case02 .article_list_case02_01 {
    width: 67%;
  }
  .article_list_case02 .article_list_case02_02 {
    width: 31%;
  }
  .article_list_case02.article_single01 .article_single01_02 {
    margin-top: 0;
  }
}

.article_container .article_single01_02 {
  margin-top: 10px;
}

.article_container .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_container .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_detail {
  margin-bottom: 4%;
  font-size: 1.5rem;
}

.article_detail .post_title {
  text-align: left;
  margin: 40px 0 2em;
  padding: 0 0 .5em !important;
  font-size: 2.4rem;
  border-bottom: #44b45c solid 2px;
}

@media (max-width: 767px) {
  .article_detail .post_title {
    font-size: 1.9rem;
  }
}

.article_detail .post_title:after {
  content: none;
}

.article_detail .post_title a:hover {
  text-decoration: none;
  color: #44b45c;
}

.article_detail .span_12 {
  margin: 2% 0;
}

.article_detail .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_detail .post_header .category_badge {
  background: #44b45c;
  color: white;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_detail .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.pager {
  overflow: hidden;
  margin-bottom: 5%;
}

.pager .pager_prev {
  float: left;
}

.pager .pager_prev a {
  display: block;
  position: relative;
  padding-left: 20px;
  text-decoration: none;
}

.pager .pager_prev a:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #44b45c;
  border-left: solid 2px #44b45c;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -6px;
}

.pager .pager_prev a:hover {
  opacity: 0.8;
}

.pager .pager_next {
  float: right;
}

.pager .pager_next a {
  display: block;
  position: relative;
  padding-right: 20px;
  text-decoration: none;
}

.pager .pager_next a:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #44b45c;
  border-right: solid 2px #44b45c;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -6px;
}

.pager .pager_next a:hover {
  opacity: 0.8;
}

.ofi {
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 75%;
}

.ofi.cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ofi > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: #44b45c;
  font-size: 2rem;
}

.text-xxlarge {
  font-size: 2.4rem;
}

.text-xlarge {
  font-size: 2rem;
}

.text-large {
  font-size: 1.6rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-xsmall {
  font-size: 1rem;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-primary {
  color: #44b45c !important;
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    font-size: 2.8rem;
  }
  .text-xxlarge {
    font-size: 3.6rem;
  }
  .text-xlarge {
    font-size: 2.8rem;
  }
  .text-large {
    font-size: 2rem;
  }
  .text-small {
    font-size: 1.2rem;
  }
  .text-xsmall {
    font-size: 1rem;
  }
  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

@media (max-width: 999px) {
  .sp-hide02, .pc-only02, .tablet-only02 {
    display: none !important;
  }
}

@media (min-width: 1000px) {
  .pc-hide02, .sp-only02 {
    display: none !important;
  }
}

.arial {
  font-family: 'Arial', arial !important;
}

.arial_n {
  font-family: 'Arial Narrow', arial !important;
}

.js-tel a {
  color: currentColor;
  text-decoration: none;
}

@media (min-width: 768px) {
  .js-tel a {
    pointer-events: none;
  }
}

.fs12 {
  font-size: 12px;
}

@media (max-width: 767px) {
  .sp-fs12 {
    font-size: 12px !important;
  }
}

.fs13 {
  font-size: 13px;
}

@media (max-width: 767px) {
  .sp-fs13 {
    font-size: 13px !important;
  }
}

.fs14 {
  font-size: 14px;
}

@media (max-width: 767px) {
  .sp-fs14 {
    font-size: 14px !important;
  }
}

.fs15 {
  font-size: 15px;
}

@media (max-width: 767px) {
  .sp-fs15 {
    font-size: 15px !important;
  }
}

.fs16 {
  font-size: 16px;
}

@media (max-width: 767px) {
  .sp-fs16 {
    font-size: 16px !important;
  }
}

.fs17 {
  font-size: 17px;
}

@media (max-width: 767px) {
  .sp-fs17 {
    font-size: 17px !important;
  }
}

.fs18 {
  font-size: 18px;
}

@media (max-width: 767px) {
  .sp-fs18 {
    font-size: 18px !important;
  }
}

.fs19 {
  font-size: 19px;
}

@media (max-width: 767px) {
  .sp-fs19 {
    font-size: 19px !important;
  }
}

.fs20 {
  font-size: 20px;
}

@media (max-width: 767px) {
  .sp-fs20 {
    font-size: 20px !important;
  }
}

.fs21 {
  font-size: 21px;
}

@media (max-width: 767px) {
  .sp-fs21 {
    font-size: 21px !important;
  }
}

.fs22 {
  font-size: 22px;
}

@media (max-width: 767px) {
  .sp-fs22 {
    font-size: 22px !important;
  }
}

.fs23 {
  font-size: 23px;
}

@media (max-width: 767px) {
  .sp-fs23 {
    font-size: 23px !important;
  }
}

.fs24 {
  font-size: 24px;
}

@media (max-width: 767px) {
  .sp-fs24 {
    font-size: 24px !important;
  }
}

.fs25 {
  font-size: 25px;
}

@media (max-width: 767px) {
  .sp-fs25 {
    font-size: 25px !important;
  }
}

.fs26 {
  font-size: 26px;
}

@media (max-width: 767px) {
  .sp-fs26 {
    font-size: 26px !important;
  }
}

.fs27 {
  font-size: 27px;
}

@media (max-width: 767px) {
  .sp-fs27 {
    font-size: 27px !important;
  }
}

.fs28 {
  font-size: 28px;
}

@media (max-width: 767px) {
  .sp-fs28 {
    font-size: 28px !important;
  }
}

.fs29 {
  font-size: 29px;
}

@media (max-width: 767px) {
  .sp-fs29 {
    font-size: 29px !important;
  }
}

.fs30 {
  font-size: 30px;
}

@media (max-width: 767px) {
  .sp-fs30 {
    font-size: 30px !important;
  }
}

.fs31 {
  font-size: 31px;
}

@media (max-width: 767px) {
  .sp-fs31 {
    font-size: 31px !important;
  }
}

.fs32 {
  font-size: 32px;
}

@media (max-width: 767px) {
  .sp-fs32 {
    font-size: 32px !important;
  }
}

.fs33 {
  font-size: 33px;
}

@media (max-width: 767px) {
  .sp-fs33 {
    font-size: 33px !important;
  }
}

.fs34 {
  font-size: 34px;
}

@media (max-width: 767px) {
  .sp-fs34 {
    font-size: 34px !important;
  }
}

.fs35 {
  font-size: 35px;
}

@media (max-width: 767px) {
  .sp-fs35 {
    font-size: 35px !important;
  }
}

.fs36 {
  font-size: 36px;
}

@media (max-width: 767px) {
  .sp-fs36 {
    font-size: 36px !important;
  }
}

.fs37 {
  font-size: 37px;
}

@media (max-width: 767px) {
  .sp-fs37 {
    font-size: 37px !important;
  }
}

.fs38 {
  font-size: 38px;
}

@media (max-width: 767px) {
  .sp-fs38 {
    font-size: 38px !important;
  }
}

.fs39 {
  font-size: 39px;
}

@media (max-width: 767px) {
  .sp-fs39 {
    font-size: 39px !important;
  }
}

.fs40 {
  font-size: 40px;
}

@media (max-width: 767px) {
  .sp-fs40 {
    font-size: 40px !important;
  }
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.mb-4 {
  margin-bottom: 4em;
}

.mb-5 {
  margin-bottom: 5em;
}

.inverse h2,
.inverse h3,
.inverse h4,
.inverse p {
  color: #fff;
}

.inverse h2:after {
  background: #fff;
}

.inverse h2 small,
.inverse h2 span {
  color: #fff;
}

.image_right {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .image_right {
    display: inline-block;
    float: right;
    margin-left: 1em;
  }
}

.dsp-home-only {
  display: none;
}

.dsp-under-only {
  display: block;
}

.home .dsp-home-only {
  display: block;
}

.home .dsp-under-only {
  display: none;
}

@media (max-width: 999px) {
  .targetOffset {
    scroll-margin-top: 60px;
  }
}

.icon-tel:before {
  content: '\f879';
  font-family: 'Font Awesome 5 Free';
  margin-right: 5px;
}

.fadeUp {
  visibility: hidden;
}

.dbl {
  display: inline-block;
}

.color1 {
  color: #44b45c;
}

.sec_top.pd {
  padding: clamp(50px, 10vw, 100px) 0;
  margin-bottom: 0;
}

.sec_top h2 .jp {
  font-size: clamp(26px, 3vw, 36px);
  line-height: calc(43 / 36);
}

.sec_top h2 .en {
  font-size: clamp(20px, 2vw, 25px);
  color: #44b45c;
  position: relative;
}

.sec_top h2.type1 {
  margin: 0 auto clamp(20px, 3vw, 40px);
}

.sec_top h2.type1 .jp {
  text-align: center;
  display: block;
}

.sec_top h2.type1 .en {
  display: block;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
}

.sec_top h2.type1 .en:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 65%;
  height: 2px;
  background: #44b45c;
  border-radius: 2px;
}

.sec_top h2.type2 .en:after {
  content: "";
  position: absolute;
  top: 0;
  margin: auto;
  height: 2px;
  background: #44b45c;
}

.sec_top h3 {
  font-weight: bold;
  text-align: center;
  font-size: clamp(20px, 2.2vw, 22px);
  margin: 0 auto 13px;
  line-height: calc(29 / 22);
}

@media screen and (max-width: 767px) {
  .sec_top h2.type2 .jp {
    text-align: center;
    display: block;
  }
  .sec_top h2.type2 .en {
    display: block;
    text-align: center;
    padding-top: 10px;
    margin-top: 10px;
  }
  .sec_top h2.type2 .en:after {
    left: 0;
    right: 0;
    width: 65%;
  }
}

@media screen and (min-width: 768px) {
  .sec_top h2.type2 {
    overflow: hidden;
  }
  .sec_top h2.type2 .en {
    display: table;
  }
  .sec_top h2.type2 .en:after {
    left: calc(100% + 1rem);
    width: 100vw;
    bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .sec_top .l2 {
    line-height: 2;
  }
}

.box-title {
  margin-bottom: 0;
}

.box-title ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
}

.box-title ul .txt__1 .fs25 {
  line-height: calc(34 / 25);
}

.box-title ul .txt__1 .bd {
  background: linear-gradient(to top, #7bd08d 8px, transparent 8px, transparent 100%);
}

.box-title ul .txt__2 {
  display: flex;
  align-items: center;
}

.box-title ul .txt__3 a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #44b45c;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  transition: all .3s;
  font-size: clamp(20px, 2.4vw, 24px);
  position: relative;
}

.box-title ul .txt__3 a:after {
  content: "";
  position: absolute;
  right: 4%;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  transition: all .3s;
}

.box-title ul .txt__3 a:hover {
  background: #286a36;
}

.box-title ul .txt__3 a:hover:after {
  right: 3%;
}

@media screen and (max-width: 767px) {
  .box-title {
    background: url("/wp-content/uploads/sp_bg_img001.png") no-repeat center/cover;
    padding: 40px 0;
  }
  .box-title ul {
    row-gap: 20px;
  }
  .box-title ul .txt {
    width: 100%;
    text-align: center;
  }
  .box-title ul .txt__1 p .fs25 {
    font-size: calc(54 / 767 * 100vw);
  }
  .box-title ul .txt__1 p .fs17 {
    font-size: calc(40 / 767 * 100vw);
    background: linear-gradient(to top, #7bd08d calc(20 / 767 * 100vw), transparent calc(20 / 767 * 100vw), transparent 100%);
  }
  .box-title ul .txt__2 {
    column-gap: 10px;
    justify-content: center;
  }
  .box-title ul .txt__3 a {
    height: 110px;
    margin: 0 auto;
    font-size: calc(34 / 767 * 100vw);
  }
}

@media screen and (min-width: 768px) {
  .box-title {
    background: url("/wp-content/uploads/bg_img001.png") no-repeat center/cover;
    padding: 40px 0;
  }
  .box-title ul .txt {
    flex: 1 1 auto;
  }
  .box-title ul .txt__1 {
    display: flex;
    align-items: center;
  }
  .box-title ul .txt__2 {
    column-gap: 10px;
    margin: 0 15px 0 20px;
  }
  .box-title ul .txt__3 a {
    height: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .box-title ul {
    gap: 20px;
  }
  .box-title ul .txt {
    width: calc((100% - 20px) / 2);
  }
  .box-title ul .txt__1 {
    width: 100%;
    justify-content: center;
  }
  .box-title ul .txt__1 p {
    text-align: center;
  }
  .box-title ul .txt__2 {
    justify-content: right;
    margin: 0;
  }
  .box-title ul .txt__3 a {
    width: 300px;
  }
}

@media screen and (min-width: 1024px) {
  .box-title ul .txt__3 a {
    width: 349px;
  }
}

.sec_1 {
  padding: clamp(50px, 6vw, 60px) 0;
  margin-bottom: 0;
}

.sec_1 .list_btn {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sec_1 .list_btn li a {
  height: 70px;
  text-decoration: none;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 5px 10px;
  color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: calc(18 / 16);
  text-align: center;
  position: relative;
  transition: all .3s;
}

.sec_1 .list_btn li a:after {
  content: "";
  position: absolute;
  right: 5px;
  bottom: 2px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left: 5px solid #44b45c;
  border-right: 0;
  transform: rotate(45deg);
  transition: all .3s;
}

.sec_1 .list_btn li a:hover {
  background: #44b45c;
  color: white;
}

.sec_1 .list_btn li a:hover:after {
  border: 5px solid transparent;
  border-left: 5px solid white;
  border-right: 0;
}

@media screen and (max-width: 767px) {
  .sec_1 .list_btn li a {
    font-size: calc(24 / 767 * 100vw);
    padding: 5px;
  }
}

@media screen and (max-width: 1023px) {
  .sec_1 .list_btn li {
    width: calc((100% - 30px) / 4);
  }
}

@media screen and (min-width: 1024px) {
  .sec_1 .list_btn li {
    width: calc((100% - 70px) / 8);
  }
}

.sec_2 {
  background: url("/wp-content/uploads/bg_img002.png") no-repeat center/cover;
}

.sec_2 .col h3 {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .sec_2 .row__2 .col:not(:last-child) {
    margin-bottom: 30px;
    border-bottom: 1px solid #44b45c;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .sec_2 .row__2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc(70 / 1920 * 100vw);
  }
  .sec_2 .row__2 .col {
    width: calc((100% - calc(140 / 1920 * 100vw)) / 3);
  }
}

.sec_3 {
  background: url("/wp-content/uploads/bg_img003.png") no-repeat center/cover;
}

.sec_3 .row .col .large {
  line-height: 1;
  white-space: nowrap;
  position: relative;
  letter-spacing: 0.08em;
  margin: 0;
  width: fit-content;
  color: transparent;
  -webkit-background-clip: text;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#d9f0de), to(#e8e8e8));
  background-image: linear-gradient(135deg, #d9f0de, #e8e8e8);
}

.sec_3 .row .col .list_btn {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.sec_3 .row .col .list_btn li a {
  width: 220px;
}

@media screen and (max-width: 767px) {
  .sec_3 {
    background-position: center left;
  }
  .sec_3 .row .col .large {
    font-size: calc(100 / 767 * 100vw);
    margin-top: calc(-84 / 767 * 100vw);
  }
  .sec_3 .row .col .list_btn {
    justify-content: center;
  }
  .sec_3 .row .col .list_btn li a {
    width: 280px;
  }
  .sec_3 .row .col__img img {
    max-width: calc(100% + 20px);
  }
}

@media screen and (min-width: 768px) {
  .sec_3 .row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0;
    flex-direction: row-reverse;
  }
  .sec_3 .row .col {
    width: 100%;
  }
  .sec_3 .row .col__img img {
    max-width: calc(100% + 20px);
  }
  .sec_3 .row .col__text .large {
    font-size: calc(151 / 1920 * 100vw);
  }
  .sec_3 .row .col__text .box-tit h2 {
    display: table;
  }
  .sec_3 .row .col__text .box-tit h2 .en:after {
    width: clamp(292px, 208.667px + 10.851vw, 417px);
  }
  .sec_3 .row .col__text .txt {
    line-height: calc(26 / 16);
  }
}

@media screen and (min-width: 1024px) {
  .sec_3 .row .col__img {
    width: 40%;
    height: calc(590 / 1679 * 100vw);
    position: relative;
    margin-top: calc(60 / 1920 * 100vw);
  }
  .sec_3 .row .col__img img {
    max-width: calc(834 / 1920 * 100vw);
  }
  .sec_3 .row .col__text {
    width: 60%;
    padding-right: calc(70 / 1920 * 100vw);
  }
}

@media screen and (min-width: 1680px) {
  .sec_3 .row .col__img {
    width: 40%;
    height: 590px;
    position: relative;
    padding-left: calc(20 / 1920 * 100vw);
  }
  .sec_3 .row .col__img img {
    max-width: none;
  }
  .sec_3 .row .col__text {
    width: 60%;
    padding-right: calc(40 / 1920 * 100vw);
  }
}

.sec_4 {
  background: url("/wp-content/uploads/bg_img004.png") no-repeat center/cover;
}

.sec_4 .row .col a {
  text-decoration: none;
  color: #1f1f1f;
  display: block;
  background: white;
  border-radius: 10px;
  padding: 10px 10px 45px;
  position: relative;
  transition: all .3s;
  box-shadow: 0 0 7px 3px rgba(31, 31, 31, 0.05);
}

.sec_4 .row .col a:before, .sec_4 .row .col a:after {
  content: "";
  position: absolute;
  transition: all .3s;
}

.sec_4 .row .col a:before {
  right: 0;
  bottom: 0;
  border-radius: 10px 0;
  background: #44b45c;
  width: 36px;
  height: 36px;
}

.sec_4 .row .col a:after {
  width: 6px;
  height: 6px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  right: 15px;
  bottom: 15px;
}

.sec_4 .row .col a .box-img {
  background: linear-gradient(to right, #f7fbf8, #e9f5ec);
  padding: 10px 10px 30px;
}

.sec_4 .row .col a .box-img p {
  font-weight: bold;
  font-size: clamp(20px, 2.6vw, 30px);
  color: #44b45c;
  text-align: center;
  margin: 0;
  line-height: 1;
}

.sec_4 .row .col a .box-text {
  padding: 10px;
}

.sec_4 .row .col a .box-text h4 {
  font-size: clamp(18px, 2vw, 20px);
  margin: 0 0 15px;
  padding-bottom: 6px;
  border-bottom: 2px solid #e1e1e1;
  position: relative;
}

.sec_4 .row .col a .box-text h4:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background: #44b45c;
  left: 0;
  bottom: -2px;
}

.sec_4 .row .col a .box-text ul {
  padding: 0;
  list-style: none;
}

.sec_4 .row .col a .box-text ul li {
  position: relative;
  padding-left: 15px;
}

.sec_4 .row .col a .box-text ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: .55em;
  width: 9px;
  height: 9px;
  background: transparent;
  border: 2px solid #44b45c;
  border-radius: 50%;
}

.sec_4 .row .col a:hover {
  background: #e5e5e5;
}

@media screen and (min-width: 768px) {
  .sec_4 .row {
    gap: calc(30 / 1920 * 100vw);
  }
  .sec_4 .row .col {
    width: calc((100% - calc(30 / 1920 * 100vw)) / 2);
    display: flex;
    flex-direction: column;
  }
  .sec_4 .row .col a {
    flex: 1 1 auto;
  }
}

.sec_5 {
  background: url("/wp-content/uploads/bg_img005.png") no-repeat center/cover;
  position: relative;
}

.sec_5 .txt_bt {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.08em;
  margin: 0;
  width: fit-content;
  color: transparent;
  -webkit-background-clip: text;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#d9f0de), to(#e8e8e8));
  background-image: linear-gradient(135deg, #d9f0de, #e8e8e8);
  z-index: 0;
}

.sec_5 .container {
  position: relative;
  z-index: 1;
}

.sec_5 .txt1 {
  font-size: clamp(20px, 2.4vw, 24px);
  line-height: calc(37 / 24);
}

.sec_5 .txt1 .bd {
  background: linear-gradient(to top, #d2eed8 clamp(10px, 1.5vw, 15px), transparent clamp(10px, 1.5vw, 15px), transparent 100%);
}

.sec_5 .txt2 {
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: clamp(24px, 3vw, 45px);
  line-height: 1;
  max-width: 80%;
  margin: 0 auto 1.5rem;
  padding: .4em 0 .6em;
}

.sec_5 .txt3 {
  display: table;
  background: #393939;
  margin: 0 auto 1.5rem;
  color: white;
  font-size: clamp(18px, 2.2vw, 22px);
  padding: .2em 10px .3em;
}

@media screen and (max-width: 767px) {
  .sec_5 .txt_bt {
    font-size: calc(66 / 767 * 100vw);
  }
}

@media screen and (min-width: 768px) {
  .sec_5 .txt_bt {
    font-size: calc(151 / 1920 * 100vw);
  }
}

.sec_6 {
  background: url("/wp-content/uploads/bg_img006.png");
}

.sec_6 .row .col:not(.btn) {
  background: white;
  color: #182327;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 7px 3px rgba(31, 31, 31, 0.05);
  padding: 10px clamp(10px, 2vw, 20px) clamp(10px, 2vw, 20px);
}

.sec_6 .row .question {
  background: white;
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid #dadedf;
  padding: 1em 1em 1em clamp(30px, 4vw, 50px);
}

.sec_6 .row .question:before {
  content: "Q";
  position: absolute;
  color: #44b45c;
  font-weight: bold;
  left: 0;
  font-size: clamp(30px, 3.4vw, 34px);
  line-height: 1;
  top: .3em;
}

.sec_6 .row .question:not(:first-child) {
  margin-top: clamp(20px, 3vw, 30px) !important;
}

.sec_6 .row .answer {
  padding-left: clamp(30px, 4vw, 50px);
  position: relative;
}

.sec_6 .row .answer:before {
  content: "A";
  position: absolute;
  color: #bbbabb;
  font-weight: bold;
  left: 0;
  font-size: clamp(30px, 3.4vw, 34px);
  line-height: 1;
  top: .5em;
}

.sec_7 .news .heading h2 {
  margin: 0 auto 1em;
}

.sec_7 .news .content dl dt {
  color: #a2a2a2;
  font-weight: 500;
}

.sec_7 .news .content dl dt .badge_category {
  font-size: 1.4rem;
  padding: 2px 5px;
  width: 70px;
  border-radius: 5px;
}

.sec_7 .news .content dl dd a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 1.5em;
  color: #1f1f1f;
}

.sec_7 .news .content dl dd a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 49%;
  right: 2%;
  margin: auto;
  width: 5px;
  height: 5px;
  border-bottom: 1px solid #44b45c;
  border-right: 1px solid #44b45c;
  transform: rotate(-45deg);
  transition: 0.2s;
}

.sec_7 .news .content dl dd a:hover {
  color: #44b45c;
}

.sec_7 .news .content dl dd a:hover:after {
  right: 1%;
}

.sec_7 .button, .sec_7 .inquiry .form_button, .inquiry .sec_7 .form_button {
  min-width: 250px;
}

@media (min-width: 768px) {
  .sec_7 .news > div {
    display: block;
  }
  .sec_7 .news > div .heading, .sec_7 .news > div .content {
    width: 100%;
  }
  .sec_7 .news > div .content {
    padding: 0;
  }
  .sec_7 .news > div .content dl dd a {
    padding-right: 2em;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .sec_7 .news > div .content dl dt {
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .sec_7 .news > div .content dl {
    font-size: 1.6rem;
    max-height: 290px;
  }
  .sec_7 .news > div .content dl dt {
    float: left;
    clear: both;
    width: 195px;
    margin: 0;
    padding: 1em 0;
  }
  .sec_7 .news > div .content dl dt + dd {
    padding-left: 195px;
  }
  .sec_7 .news > div .content dl dd {
    padding: 1em 0;
  }
}

.sec_8 {
  background: #f9f9f9;
}

.sec_8 .tit {
  font-size: 24px;
  margin-bottom: clamp(10px, 2vw, 20px);
}

.sec_8 dl {
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  padding-top: clamp(10px, 1.5vw, 15px);
  padding-bottom: clamp(10px, 1.5vw, 15px);
  margin: 0;
}

.sec_8 dl dt {
  width: 70px;
  float: left;
  clear: both;
  position: relative;
  padding-left: 15px;
}

.sec_8 dl dt:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: #44b45c;
}

.sec_8 dl dt + dd {
  padding-left: 70px;
}

.sec_8 dl dd {
  margin: 0;
}

.sec_8 .tel_num {
  line-height: 1;
  margin-bottom: clamp(20px, 3vw, 30px);
}

.sec_8 .row__1 {
  margin-bottom: 30px;
}

.sec_8 .row__2 {
  margin-bottom: clamp(50px, 6vw, 70px);
}

.sec_8 .row__3 {
  display: flex;
}

.sec_8 .row__3 .col a {
  background: white;
  border: 2px solid;
  font-size: clamp(20px, 2.2vw, 22px);
  position: relative;
  text-decoration: none;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: calc(28 / 22);
  transition: all .3s;
}

.sec_8 .row__3 .col a:before {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 0;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left: 6px solid #44b45c;
  border-right: 0;
  transform: rotate(45deg);
  transition: all .3s;
}

.sec_8 .row__3 .col a:hover {
  background: #44b45c;
  color: white;
}

.sec_8 .row__3 .col a:hover:before {
  border: 6px solid transparent;
  border-left: 6px solid white;
  border-right: 0;
}

@media screen and (max-width: 767px) {
  .sec_8 .row__3 {
    gap: 10px;
    flex-wrap: wrap;
  }
  .sec_8 .row__3 .col {
    width: 100%;
    margin: 0;
  }
  .sec_8 .row__3 .col a {
    font-size: 22px;
  }
}

@media screen and (min-width: 768px) {
  .sec_8 .row__3 {
    gap: 20px;
  }
  .sec_8 .row__3 .col {
    width: calc((100% - 40px) / 3);
  }
}

@media screen and (min-width: 1024px) {
  .sec_8 .row__2 {
    display: flex;
    align-items: center;
    column-gap: 0;
  }
  .sec_8 .row__2 .box-img {
    width: 521px;
  }
  .sec_8 .row__2 .box-text {
    width: calc(100% - 521px);
    padding-right: calc(40 / 1920 * 100vw);
  }
}

.sec_9 {
  background: linear-gradient(to right, #d9f0de, #ebf3ed);
  padding: 50px 0;
  margin-bottom: 0 !important;
}

.sec_9 .row {
  flex-direction: column;
}

.sec_9 .row .box__1 {
  display: flex;
}

.sec_9 .row .box__1 .tit {
  border-bottom: 4px solid #a3d9ae;
  padding-bottom: 10px;
}

.sec_9 .row .box__1 ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.sec_9 .row .box__1 ul li {
  width: 150px;
  height: 150px;
  border: 3px solid;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 23px;
  line-height: calc(25 / 23);
}

.sec_9 .row .box__1 ul li.cl1 {
  color: #44b45c;
}

.sec_9 .row .box__1 ul li.cl2 {
  color: #44b4a5;
}

.sec_9 .row .box__2 .button, .sec_9 .row .box__2 .inquiry .form_button, .inquiry .sec_9 .row .box__2 .form_button {
  max-width: 100%;
  font-size: clamp(20px, 2.4vw, 24px);
  height: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec_9 .row .box__2 .button:after, .sec_9 .row .box__2 .inquiry .form_button:after, .inquiry .sec_9 .row .box__2 .form_button:after {
  width: 8px;
  height: 8px;
  border-width: 2px;
}

@media screen and (max-width: 767px) {
  .sec_9 .row .box__1 {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .sec_9 .row .box__1 .tit {
    font-size: calc(45 / 767 * 100vw);
    text-align: center;
    margin-bottom: 15px;
  }
  .sec_9 .row .box__1 .tit .color1 {
    font-size: calc(54 / 767 * 100vw);
  }
  .sec_9 .row .box__1 ul {
    justify-content: center;
  }
  .sec_9 .row .box__2 .tel_num {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .sec_9 {
    padding: 40px 0;
  }
  .sec_9 .row .box__1 {
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
  }
  .sec_9 .row .box__1 .tit {
    font-size: clamp(18px, 2.4vw, 25px);
  }
  .sec_9 .row .box__1 .tit .color1 {
    font-size: clamp(26px, 3vw, 32px);
  }
  .sec_9 .row .box__2 .tel_num {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec_9 .row .box__2 .tel_num .txt {
    border-left: 1px solid #1f1f1f;
    padding-left: 10px;
    margin-left: 10px;
    padding-bottom: 3px;
  }
}

.ic {
  position: relative;
}

.ic:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  bottom: 0;
  margin: auto;
}

.ic__contact {
  padding-left: 25px;
}

.ic__contact:before {
  width: 18px;
  height: 13px;
  background: url("/wp-content/uploads/ic_mail.png") no-repeat center/contain;
}

.ic__contact2 {
  padding-left: 30px;
}

.ic__contact2:before {
  width: 24px;
  height: 17px;
  background: url("/wp-content/uploads/ic_mail2.png") no-repeat center/contain;
}

.tel_num .js-tel {
  font-size: 3rem;
  position: relative;
  padding-left: 25px;
  font-weight: 500;
  color: #44b45c;
}

.tel_num .js-tel:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 22px;
  background: url("/wp-content/uploads/ic_tel.png") no-repeat center center/cover;
}

.tel_num .js-tel a {
  color: #44b45c;
}

.anchor_list {
  background: rgba(68, 180, 92, 0.1);
  padding: 1em;
  list-style: none;
  border-radius: 10px;
}

.anchor_list li {
  margin: .5em .5em;
}

.anchor_list li a {
  padding-left: 20px;
  position: relative;
  color: #1f1f1f;
  text-decoration: none;
  transition: all .3s;
}

.anchor_list li a:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #44b45c;
  position: absolute;
  top: .5em;
  left: 0;
}

.anchor_list li a:hover {
  color: #44b45c;
}

@media (min-width: 768px) {
  .anchor_list {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5em 2em;
    gap: 5px 2%;
  }
  .anchor_list.anchor_center {
    justify-content: center;
  }
  .anchor_list.col1 li {
    width: 100%;
  }
  .anchor_list li {
    width: calc(98% / 2);
    margin: 0;
  }
  .anchor_list.col3 li {
    width: calc(96% / 3);
  }
}

.box_bg {
  background: rgba(68, 180, 92, 0.1);
  padding: 4%;
  border-radius: 10px;
}

.box_bg:not(:last-child) {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .box_bg.col {
    margin-bottom: 0;
  }
}

.box_bg2 {
  background: rgba(68, 180, 165, 0.1);
  padding: 4%;
  border-radius: 10px;
}

.box_border {
  border: 1px solid #44b45c;
  padding: 4%;
  border-radius: 10px;
  margin-top: 0 !important;
}

ul.box_border {
  list-style: none;
}

section.highlight:last-child {
  margin-bottom: 0;
}

section.highlight .box_bg {
  background: white;
}

@media screen and (min-width: 768px) {
  .list2 {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 2%;
  }
  .list2 li {
    width: 49%;
  }
}

@media screen and (min-width: 768px) {
  .list3 {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 2%;
  }
  .list3 li {
    width: calc(96% / 3);
  }
}
