@charset "utf-8";
//*****************TOP*****************//
.dbl{
  display: inline-block;
}
.color1{
  color: $color_primary;
}

.sec_top{
  &.pd{
    padding: clamp(50px, 10vw, 100px) 0;
    margin-bottom: 0;
  }

  h2{
    .jp{
      font-size: clamp(26px, 3vw, 36px);
      line-height: calc(43 / 36);
    }
    .en{
      font-size: clamp(20px, 2vw, 25px);
      color: $color_primary;
      position: relative;
    }
    &.type1{
      margin: 0 auto clamp(20px, 3vw, 40px);
      .jp{
        text-align: center;
        display: block;
      }
      .en{
        display: block;
        text-align: center;
        padding-top: 10px;
        margin-top: 10px;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 65%;
          height: 2px;
          background: $color_primary;
          border-radius: 2px;
        }
      }
    }

    &.type2{
      .en{
        &:after{
          content: "";
          position: absolute;
          top: 0;
          margin: auto;
          height: 2px;
          background: $color_primary;
        }
      }
    }
  }

  h3{
    font-weight: bold;
    text-align: center;
    font-size: clamp(20px, 2.2vw, 22px);
    margin: 0 auto 13px;
    line-height: calc(29 / 22);
  }

  @media screen and (max-width: 767px) {
    h2{
      &.type2{
        .jp{
          text-align: center;
          display: block;
        }
        .en{
          display: block;
          text-align: center;
          padding-top: 10px;
          margin-top: 10px;
          &:after{
            left: 0;
            right: 0;
            width: 65%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    h2{
      &.type2{
        overflow: hidden;
        .en{
          display: table;
          &:after{
            left: calc(100% + 1rem);
            width: 100vw;
            bottom: 0;
            
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .l2{
      line-height: 2;
    }
  }
}
.box-title{
  margin-bottom: 0;
  ul{
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
    .txt{
      &__1{
        .fs25{
          line-height: calc(34 / 25);
        }
        .bd{
          background: linear-gradient(to top, #7bd08d 8px, transparent 8px, transparent 100%);
        }
      }
      &__2{
        display: flex;
        align-items: center;
      }
      &__3{
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color_primary;
          color: white;
          text-decoration: none;
          border-radius: 20px;
          transition: all .3s;
          font-size: clamp(20px, 2.4vw, 24px);
          position: relative;
          &:after{
            content: "";
            position: absolute;
            right: 4%;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 6px;
            height: 6px;
            border-right: 2px solid white;
            border-bottom: 2px solid white;
            transform: rotate(-45deg);
            transition: all .3s;
          }
          &:hover{
            background: darken($color_primary, 20%);
            &:after{
              right: 3%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    background: url("/wp-content/uploads/sp_bg_img001.png") no-repeat center/cover;
    padding: 40px 0;
    ul{
      row-gap: 20px;
      .txt{
        width: 100%;
        text-align: center;
        &__1{
          p{
            .fs25{
              font-size: calc(54 / 767 * 100vw);
            }
            .fs17{
              font-size: calc(40 / 767 * 100vw);
              background: linear-gradient(to top, #7bd08d calc(20 / 767 * 100vw), transparent calc(20 / 767 * 100vw), transparent 100%);
            }
          }
        }
        &__2{
          column-gap: 10px;
          justify-content: center;
        }
        &__3{
          a{
            height: 110px;
            margin: 0 auto;
            font-size: calc(34 / 767 * 100vw);
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    background: url("/wp-content/uploads/bg_img001.png") no-repeat center/cover;
    padding: 40px 0;
    ul{
      .txt{
        flex: 1 1 auto;
        &__1{
          display: flex;
          align-items: center;
        }
        &__2{
          column-gap: 10px;
          margin: 0 15px 0 20px;
        }
        &__3{
          a{
            height: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      ul{
        gap: 20px;
        .txt{
          width: calc((100% - 20px) / 2);
          &__1{
            width: 100%;
            justify-content: center;
            p{
              text-align: center;
            }
          }
          &__2{
            justify-content: right;
            margin: 0;
          }
          &__3{
            a{
              width: 300px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    ul{
      .txt{
        &__3{
          a{
            width: 349px;

          }
        }
      }
    }
  }
}

.sec_1{
  padding: clamp(50px, 6vw, 60px) 0;
  margin-bottom: 0;
  .list_btn{
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li{
      a{
        height: 70px;
        text-decoration: none;
        background: #f2f2f2;
        border-radius: 8px;
        padding: 5px 10px;
        color: $color_text;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        line-height: calc(18 / 16);
        text-align: center;
        position: relative;
        transition: all .3s;
        &:after{
          content: "";
          position: absolute;
          right: 5px;
          bottom: 2px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-left: 5px solid $color_primary;
          border-right: 0;
          transform: rotate(45deg);
          transition: all .3s;
        }

        &:hover{
          background: $color_primary;
          color: white;
          &:after{
            border: 5px solid transparent;
            border-left: 5px solid white;
            border-right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .list_btn{
      li{
        a{
          font-size: calc(24 / 767 * 100vw);
          padding: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .list_btn{
      li{
        width: calc((100% - 30px) / 4);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .list_btn{
      li{
        width: calc((100% - 70px) / 8);
      }
    }
  }
}

.sec_2{
  background: url("/wp-content/uploads/bg_img002.png") no-repeat center/cover;
  .col{
    h3{
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    .row{
      &__2{
        .col{
          &:not(:last-child){
            margin-bottom: 30px;
            border-bottom: 1px solid $color_primary;
            padding-bottom: 30px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .row{
      &__2{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: calc(70 / 1920 * 100vw);
        .col{
          width: calc((100% - calc(140 / 1920 * 100vw)) / 3);
        }
      }
    }
  }
}

.sec_3{
  background: url("/wp-content/uploads/bg_img003.png") no-repeat center/cover;
  .row{
    .col{
      .large{
        line-height: 1;
        white-space: nowrap;
        position: relative;
        letter-spacing: 0.08em;
        margin: 0;
        width: fit-content;
        color: transparent;
        -webkit-background-clip: text;
        background-image: -webkit-gradient(linear, left top, right bottom, from(#d9f0de), to(#e8e8e8));
        background-image: linear-gradient(135deg, #d9f0de, #e8e8e8);
      }
      .list_btn{
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        li{
          a{
            width: 220px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-position: center left;
    .row{
      .col{
        .large{
          font-size: calc(100 / 767 * 100vw);
          margin-top: calc(-84 / 767 * 100vw);
        }
        .list_btn{
          justify-content: center;
          li{
            a{
              width: 280px;
            }
          }
        }

        &__img{
          img{
            max-width: calc(100% + 20px);
          }
        }
      }
    }  
  }

  @media screen and (min-width: 768px) {
    .row{
      display: flex;
      flex-wrap: wrap;
      column-gap: 0;
      flex-direction: row-reverse;
      .col{
        width: 100%;
        &__img{
          img{
            max-width: calc(100% + 20px);
          }
        }
        &__text{
          .large{
            font-size: calc(151 / 1920 * 100vw);
          }
          .box-tit{
            h2{
              display: table;
              .en{
                &:after{
                  width: #{'clamp(292px, 208.667px + 10.851vw, 417px)'};
                }
              }
            }
          }
          .txt{
            line-height: calc(26 / 16);
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .row{
      .col{
        &__img{
          width: 40%;
          height: calc(590 / 1679 * 100vw);
          position: relative;
          margin-top: calc(60 / 1920 * 100vw);
          img{
            max-width: calc(834 / 1920 * 100vw);
          }
        }

        &__text{
          width: 60%;
          padding-right: calc(70 / 1920 * 100vw);
        }
      }
    }
  }

  @media screen and (min-width: 1680px) {
    .row{
      .col{
        &__img{
          width: 40%;
          height: 590px;
          position: relative;
          padding-left: calc(20 / 1920 * 100vw);
          img{
            max-width: none;
          }
        }
        &__text{
          width: 60%;
          padding-right: calc(40 / 1920 * 100vw);
        }
      }
    }
  }
}

.sec_4{
  background: url("/wp-content/uploads/bg_img004.png") no-repeat center/cover;
  .row{
    .col{
      a{
        text-decoration: none;
        color: $color_text;
        display: block;
        background: white;
        border-radius: 10px;
        padding: 10px 10px 45px;
        position: relative;
        transition: all .3s;
        box-shadow: 0 0 7px 3px rgba($color_text, .05);
        &:before,&:after{
          content: "";
          position: absolute;
          transition: all .3s;
        }
        &:before{
          right: 0;
          bottom: 0;
          border-radius: 10px 0;
          background: $color_primary;
          width: 36px;
          height: 36px;
        }

        &:after{
          width: 6px;
          height: 6px;
          border-right: 2px solid white;
          border-bottom: 2px solid white;
          transform: rotate(-45deg);
          right: 15px;
          bottom: 15px;
        }
        .box-img{
          background: linear-gradient(to right, #f7fbf8, #e9f5ec);
          padding: 10px 10px 30px;
          p{
            font-weight: bold;
            font-size: clamp(20px, 2.6vw, 30px);
            color: $color_primary;
            text-align: center;
            margin: 0;
            line-height: 1;
          }
        }

        .box-text{
          padding: 10px;
          h4{
            font-size: clamp(18px, 2vw, 20px);
            margin: 0 0 15px;
            padding-bottom: 6px;
            border-bottom: 2px solid #e1e1e1;
            position: relative;
            &:before{
              content: "";
              position: absolute;
              width: 50px;
              height: 2px;
              background: $color_primary;
              left: 0;
              bottom: -2px;

            }
          }
          ul{
            padding: 0;
            list-style: none;
            li{
              position: relative;
              padding-left: 15px;
              &:before{
                content: "";
                position: absolute;
                left: 0;
                top: .55em;
                width: 9px;
                height: 9px;
                background: transparent;
                border: 2px solid $color_primary;
                border-radius: 50%;
              }
            }
          }
        }

        &:hover{
          background: #e5e5e5;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .row{
      gap: calc(30 / 1920 * 100vw);
      .col{
        width: calc((100% - calc(30 / 1920 * 100vw)) / 2);
        display: flex;
        flex-direction: column;
        a{
          flex: 1 1 auto;
        }
      }
    }
  }
}

.sec_5{
  background: url("/wp-content/uploads/bg_img005.png") no-repeat center/cover;
  position: relative;
  .txt_bt{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    bottom: 0;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
    white-space: nowrap;
    letter-spacing: 0.08em;
    margin: 0;
    width: fit-content;
    color: transparent;
    -webkit-background-clip: text;
    background-image: -webkit-gradient(linear, left top, right bottom, from(#d9f0de), to(#e8e8e8));
    background-image: linear-gradient(135deg, #d9f0de, #e8e8e8);
    z-index: 0;
  }

  .container{
    position: relative;
    z-index: 1;
  }

  .txt1{
    font-size: clamp(20px, 2.4vw, 24px);
    line-height: calc(37 / 24);
    .bd{
      background: linear-gradient(to top, #d2eed8 clamp(10px, 1.5vw, 15px), transparent clamp(10px, 1.5vw, 15px), transparent 100%);
    }
  }

  .txt2{
    border-top: 2px solid;
    border-bottom: 2px solid;
    font-size: clamp(24px, 3vw, 45px);
    line-height: 1;
    max-width: 80%;
    margin: 0 auto 1.5rem;
    padding: .4em 0 .6em;
  }

  .txt3{
    display: table;
    background: #393939;
    margin: 0 auto 1.5rem;
    color: white;
    font-size: clamp(18px, 2.2vw, 22px);
    padding: .2em 10px .3em;
  }

  @media screen and (max-width: 767px) {
    .txt_bt{
      font-size: calc(66 / 767 * 100vw);
    }
  }

  @media screen and (min-width: 768px) {
    .txt_bt{
      font-size: calc(151 / 1920 * 100vw);
    }
  }
}

.sec_6{
  background: url("/wp-content/uploads/bg_img006.png");
  .row{
    .col{
      &:not(.btn){
        background: white;
        color: #182327;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 7px 3px rgba($color_text, .05);
        padding: 10px clamp(10px, 2vw, 20px) clamp(10px, 2vw, 20px);
      }
    }
    .question{
      background: white;
      font-size: 18px;
      text-align: left;
      font-weight: 500;
      border-bottom: 1px solid #dadedf;
      padding: 1em 1em 1em clamp(30px, 4vw, 50px);
      &:before{
        content: "Q";
        position: absolute;
        color: $color_primary;
        font-weight: bold;
        left: 0;
        font-size: clamp(30px, 3.4vw, 34px);
        line-height: 1;
        top: .3em;
      }
      &:not(:first-child){
        margin-top: clamp(20px, 3vw, 30px)!important;
      }
    }
    .answer{
      padding-left: clamp(30px, 4vw, 50px);
      position: relative;
      &:before{
        content: "A";
        position: absolute;
        color: #bbbabb;
        font-weight: bold;
        left: 0;
        font-size: clamp(30px, 3.4vw, 34px);
        line-height: 1;
        top: .5em;
      }
    }
  }
}

.sec_7{
  .news{
    .heading{
      h2{
        margin: 0 auto 1em;
      }
    }
    .content{
      dl{
        dt{
          color: #a2a2a2;
          font-weight: 500;
          .badge_category{
            @include fontsize(14);
            padding: 2px 5px;
            width: 70px;
            border-radius: 5px;
          }
        }
        dd{
          a{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            padding-right: 1.5em;
            color: $color_text;
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 49%;
              right: 2%;
              margin: auto;
              width: 5px;
              height: 5px;
              border-bottom: 1px solid $color_primary;
              border-right: 1px solid $color_primary;
              transform: rotate(-45deg);
              transition: 0.2s;
            }

            &:hover{
              color: $color_primary;
              &:after{
                right: 1%;
              }
            }
          }
        }
      }
    }
  }

  .button{
    min-width: 250px;
  }

  @media(min-width: 768px){
    .news {
      > div{
        display: block;
        .heading,.content{
          width: 100%;
        }

        .content{
          padding: 0;
          dl{
            dd{
              a{
                padding-right: 2em;
              }
            }
          }
        }
      }
    }

    @media(max-width: 999px){
      .news{
        >div{
          .content{
            dl{
              dt{
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  @media(min-width: 1000px){
    .news{
      > div{
        .content{
          dl{
            @include fontsize(16);
            max-height: 290px;
            dt{
              float: left;
              clear: both;
              width: 195px;
              margin: 0;
              padding: 1em 0;
              +dd{
                padding-left: 195px;
              }
            }
            dd{
              padding: 1em 0;
            }
          }
        }
      }
    }
  }
}

.sec_8{
  background: #f9f9f9;
  .tit{
    font-size: 24px;
    margin-bottom: clamp(10px, 2vw, 20px);
  }
  dl{
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding-top: clamp(10px, 1.5vw, 15px);
    padding-bottom: clamp(10px, 1.5vw, 15px);
    margin: 0;
    dt{
      width: 70px;
      float: left;
      clear: both;
      position: relative;
      padding-left: 15px;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        background: $color_primary;

      }
      +dd{
        padding-left: 70px;
      }
    }
    dd{
      margin: 0;
    }
  }

  .tel_num{
    line-height: 1;
    margin-bottom: clamp(20px, 3vw, 30px);
  }

  .row{
    &__1{
      margin-bottom: 30px;
    }
    &__2{
      margin-bottom: clamp(50px, 6vw, 70px);
    }
    &__3{
      display: flex;
      .col{
        a{
          background: white;
          border: 2px solid;
          font-size: clamp(20px, 2.2vw, 22px);
          position: relative;
          text-decoration: none;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          line-height: calc(28 / 22);
          transition: all .3s;
          &:before{
            content: "";
            position: absolute;
            right: 3px;
            bottom: 0;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left: 6px solid $color_primary;
            border-right: 0;
            transform: rotate(45deg);
            transition: all .3s;
          }

          &:hover{
            background: $color_primary;
            color: white;
            &:before{
              border: 6px solid transparent;
              border-left: 6px solid white;
              border-right: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .row{
      &__3{
        gap: 10px;
        flex-wrap: wrap;
        .col{
          width: 100%;
          margin: 0;
          a{
            font-size: 22px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .row{
      &__3{
        gap: 20px;
        .col{
          width: calc((100% - 40px) / 3);
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .row{
      &__2{
        display: flex;
        align-items: center;
        column-gap: 0;
        .box-img{
          width: 521px;
        }
        .box-text{
          width: calc(100% - 521px);
          padding-right: calc(40 / 1920 * 100vw);
        }
      }
    }
  }
}

.sec_9{
  background: linear-gradient(to right, #d9f0de, #ebf3ed);
  padding: 50px 0;
  margin-bottom: 0!important;
  .row{
    flex-direction: column;
    .box{
      &__1{
        display: flex;
        .tit{
          border-bottom: 4px solid #a3d9ae;
          padding-bottom: 10px;
        }
        ul{
          list-style: none;
          padding: 0;
          display: flex;
          gap: 10px;
          li{
            width: 150px;
            height: 150px;
            border: 3px solid;
            font-weight: bold;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 23px;
            line-height: calc(25 / 23);
            &.cl1{
              color: $color_primary;
            }
            &.cl2{
              color: #44b4a5;
            }
          }
        }
      }

      &__2{
        .button{
          max-width: 100%;
          font-size: clamp(20px, 2.4vw, 24px);
          height: 100px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:after{
            width: 8px;
            height: 8px;
            border-width: 2px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .row{
      .box{
        &__1{
          flex-direction: column;
          margin-bottom: 15px;
          .tit{
            font-size: calc(45 / 767 * 100vw);
            text-align: center;
            margin-bottom: 15px;
            .color1{
              font-size: calc(54 / 767 * 100vw);
            }
          }
          ul{
            justify-content: center;
          }
        }
        &__2{
          .tel_num{
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 40px 0;
    .row{
      .box{
        &__1{
          align-items: center;
          justify-content: space-between;
          column-gap: 10px;
          .tit{
            font-size: clamp(18px, 2.4vw, 25px);
            .color1{
              font-size: clamp(26px, 3vw, 32px);
            }
          }
        }

        &__2{
          .tel_num{
            display: flex;
            align-items: center;
            justify-content: center;
            .txt{
              border-left: 1px solid $color_text;
              padding-left: 10px;
              margin-left: 10px;
              padding-bottom: 3px;
            }
          }
        }
      }
    }
  }
}