@charset "UTF-8";
.button {
  display: inline-block;
  max-width: $button_width;
  width: 100%;
  margin: 0;
  padding: .9em 1em 1.1em;
  border: $style_button_border;
  border-radius: $style_button_border_radius;
  line-height: 1;
  background: $color_button_bg;
  color: $color_button_text;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  @include fontsize(16);
  font-weight: bold;
  cursor: pointer;
  border-radius: 40px;

  &:hover {
    background: $color_button_bg_hover;
    color: $color_button_text_hover;
    border-color: $color_button_border_hover;
  }

  /*
* ボタン矢印
*/
  &.arrow {
    position: relative;
    @include fontsize(16);
    line-height: 1.4;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 46%;
      right: 5%;
      margin: auto;
      width: 5px;
      height: 5px;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      transform: rotate(-45deg);
      transition: 0.2s;
    }

    &:hover {
      &:after {
        right: 4%;
      }
    }
  }

  .highlight & {
    color: $color_button_text;

    &:hover {
      color: $color_button_text_hover;
    }
  }

  &.inverse {
    background: none;
    border-color: $color_primary;
    color: $color_primary;

    &:after {
      border-color: $color_primary;
    }

    &:hover {
      background: $color_primary;
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }
  &.large{
    max-width: max-content;
    padding-left: 2em;
    padding-right: 2em;
  }

  &.block {
    display: block;
    min-width: 100%;
  }

  &.small {
    padding: .6em 1em;
    max-width: unset;
    width: auto;

    &.arrow {
      padding-right: 2.5em;
    }
  }

  + .button {
    margin-left: 0.5em;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 1em;
    }
  }
}
